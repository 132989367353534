import React from 'react';
import { MainPageLayout } from '../layout/MainPageLayout';
import { FactoryNavigationBar } from '../component/navigation-bar/FactoryNavigationBar';
import { DeviceLicenseDiv } from '../component/device-license/DeviceLicenseDiv';

export const DeviceLicensePage = () => (
  <MainPageLayout
    navigationBar={<FactoryNavigationBar />}
    elementCentr={<DeviceLicenseDiv />}
  />
);
