import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PageviewIcon from '@mui/icons-material/Pageview';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import {
  formatDateArch,
  formatVolumeToChart,
  getDefaultAlertProps,
  getDefaultDateToReport,
  replaceQuoat,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import { MAIN_COLOR_STRING, VIEW_ARCHIVE } from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DeviceArchiveDto } from '../../dto/DeviceArchiveDto';
import { ReportsTypeEnum } from '../../enum/ReportsTypeEnum';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetHours } from '../../service/archive/apiGetHours';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiGetDays } from '../../service/archive/apiGetDays';
import { MyDateTextField } from '../UI/MyDateTextField';
import { DeviceInfoDto } from '../../dto/DeviceInfoDto';

export const DeviceChartDiv = () => {
  const { id } = useParams();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [dateTimeArch, setDateTimeArch] = useState(getDefaultDateToReport(0));
  const [hourArchiveDtos, setHourArchiveDtos] = useState<DeviceArchiveDto[]>(
    []
  );
  const [dayArchiveDtos, setDayArchiveDtos] = useState<DeviceArchiveDto[]>([]);
  const [checkParam, setCheckParam] = useState({
    volumeSum: true,
    volume: true,
    temp: false,
  });

  const [period, setPeriod] = useState('date');
  const [chartType, setChartType] = useState('line');
  const [deviceInfoDto, setDeviceDto] = useState<DeviceInfoDto>();

  const handleChangeChart = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChartType((event.target as HTMLInputElement).value);
  };

  const getArch = async (
    idDevice: string,
    type: number,
    print: string,
    datetime?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    name?: string
  ) => {
    setBackDrop(true);
    if (type === ReportsTypeEnum.HOUR) {
      const apiResult: IApiResult = await apiGetHours(
        idDevice,
        print,
        datetime
      );
      if (apiResult.isSuccess) {
        setHourArchiveDtos(formatVolumeToChart(apiResult.data.hour.reverse()));
        document.title = `${t('flowMeter')} № ${apiResult.data.info.deviceNum}`;
        setDeviceDto(apiResult.data.info);
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    if (type === ReportsTypeEnum.DAY) {
      const apiResult: IApiResult = await apiGetDays(idDevice, print, datetime);
      if (apiResult.isSuccess) {
        setDayArchiveDtos(formatVolumeToChart(apiResult.data.day.reverse()));
        document.title = `${t('flowMeter')} № ${apiResult.data.info.deviceNum}`;
        setDeviceDto(apiResult.data.info);
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    setBackDrop(false);
  };

  const handleChangePeriod = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPeriod((event.target as HTMLInputElement).value);
    setDateTimeArch(
      getDefaultDateToReport(0, (event.target as HTMLInputElement).value)
    );
    await getArch(
      id ?? '',
      (event.target as HTMLInputElement).value === 'date'
        ? ReportsTypeEnum.HOUR
        : ReportsTypeEnum.DAY,
      VIEW_ARCHIVE,
      formatDateArch(
        getDefaultDateToReport(0, (event.target as HTMLInputElement).value)
      )
    );
  };

  const handleChangeVolumeSum = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = false;
    if (event.target.checked) {
      check = true;
    }
    setCheckParam({ ...checkParam, volumeSum: check });
  };

  const handleChangeVolume = (event: React.ChangeEvent<HTMLInputElement>) => {
    let check = false;
    if (event.target.checked) {
      check = true;
    }
    setCheckParam({ ...checkParam, volume: check });
  };

  const handleChangeTemp = (event: React.ChangeEvent<HTMLInputElement>) => {
    let check = false;
    if (event.target.checked) {
      check = true;
    }
    setCheckParam({ ...checkParam, temp: check });
  };

  const handleViewArchive = async () => {
    await getArch(
      id ?? '',
      period === 'date' ? ReportsTypeEnum.HOUR : ReportsTypeEnum.DAY,
      VIEW_ARCHIVE,
      formatDateArch(dateTimeArch)
    );
  };

  const findMinMaxHour = () => {
    let minFlowSum = 100000;
    let maxFlowSum = 0;
    let minFlow = 100000;
    let maxFlow = 0;
    let minT = 100000;
    let maxT = 0;
    for (let i = 0; i < hourArchiveDtos.length; i += 1) {
      if (minFlow > parseFloat(hourArchiveDtos[i].volumeSu)) {
        minFlow = parseFloat(hourArchiveDtos[i].volumeSu);
      }
      if (maxFlow < parseFloat(hourArchiveDtos[i].volumeSu)) {
        maxFlow = parseFloat(hourArchiveDtos[i].volumeSu);
      }
      if (minT > parseFloat(hourArchiveDtos[i].temp)) {
        minT = parseFloat(hourArchiveDtos[i].temp);
      }
      if (maxT < parseFloat(hourArchiveDtos[i].temp)) {
        maxT = parseFloat(hourArchiveDtos[i].temp);
      }
      if (minFlowSum > parseFloat(hourArchiveDtos[i].volumeSuSum)) {
        minFlowSum = parseFloat(hourArchiveDtos[i].volumeSuSum);
      }
      if (maxFlowSum < parseFloat(hourArchiveDtos[i].volumeSuSum)) {
        maxFlowSum = parseFloat(hourArchiveDtos[i].volumeSuSum);
      }
    }
    minFlow = Math.round(minFlow) - 1;
    maxFlow = Math.round(maxFlow) + 1;
    minT = Math.round(minT) - 1;
    maxT = Math.round(maxT) + 1;
    minFlowSum = Math.round(minFlowSum) - 2;
    maxFlowSum = Math.round(maxFlowSum) + 2;
    if (minFlow < 0) {
      minFlow = 0;
    }
    if (minFlowSum < 0) {
      minFlowSum = 0;
    }
    const result = [minFlow, maxFlow, minT, maxT, minFlowSum, maxFlowSum];
    return result;
  };

  const findMinMaxDay = () => {
    let minFlowSum = 100000;
    let maxFlowSum = 0;
    let minFlow = 100000;
    let maxFlow = 0;
    let minT = 100000;
    let maxT = 0;
    for (let i = 0; i < dayArchiveDtos.length; i += 1) {
      if (minFlow > parseFloat(dayArchiveDtos[i].volumeSu)) {
        minFlow = parseFloat(dayArchiveDtos[i].volumeSu);
      }
      if (maxFlow < parseFloat(dayArchiveDtos[i].volumeSu)) {
        maxFlow = parseFloat(dayArchiveDtos[i].volumeSu);
      }
      if (minT > parseFloat(dayArchiveDtos[i].temp)) {
        minT = parseFloat(dayArchiveDtos[i].temp);
      }
      if (maxT < parseFloat(dayArchiveDtos[i].temp)) {
        maxT = parseFloat(dayArchiveDtos[i].temp);
      }
      if (minFlowSum > parseFloat(dayArchiveDtos[i].volumeSuSum)) {
        minFlowSum = parseFloat(dayArchiveDtos[i].volumeSuSum);
      }
      if (maxFlowSum < parseFloat(dayArchiveDtos[i].volumeSuSum)) {
        maxFlowSum = parseFloat(dayArchiveDtos[i].volumeSuSum);
      }
    }
    minFlow = Math.round(minFlow) - 1;
    maxFlow = Math.round(maxFlow) + 1;
    minT = Math.round(minT) - 1;
    maxT = Math.round(maxT) + 1;
    minFlowSum = Math.round(minFlowSum) - 2;
    maxFlowSum = Math.round(maxFlowSum) + 2;
    if (minFlow < 0) {
      minFlow = 0;
    }
    if (minFlowSum < 0) {
      minFlowSum = 0;
    }
    const result = [minFlow, maxFlow, minT, maxT, minFlowSum, maxFlowSum];
    return result;
  };

  const dpLimit = period === 'date' ? findMinMaxHour() : findMinMaxDay();

  useEffect(() => {
    handleViewArchive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv
            title={t('chart')}
            isFlowmeter
            deviceInfo={
              deviceInfoDto &&
              `${t('flowMeter')} № ${deviceInfoDto?.deviceNum} ${replaceQuoat(
                deviceInfoDto?.holder,
                0
              )}`
            }
          />
        </Stack>
        <Stack direction="column" width="90%" spacing={2}>
          <Card style={{ padding: '20px' }}>
            <Stack direction="column" spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  spacing={2}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                    spacing={2}
                  >
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        {t('reportPeriod')}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="date"
                        onChange={handleChangePeriod}
                      >
                        <FormControlLabel
                          value="date"
                          control={<Radio />}
                          label={t('day')}
                        />
                        <FormControlLabel
                          value="month"
                          control={<Radio />}
                          label={t('month')}
                        />
                      </RadioGroup>
                    </FormControl>
                    <MyDateTextField
                      type={period}
                      value={dateTimeArch}
                      onChange={(e) => setDateTimeArch(e.target.value)}
                      width="200px"
                      label={t('date')}
                      size="small"
                    />
                    <IconButton
                      size="large"
                      onClick={handleViewArchive}
                      title={t('tooltipLook')}
                      sx={{ color: MAIN_COLOR_STRING }}
                    >
                      <PageviewIcon fontSize="large" />
                    </IconButton>
                  </Stack>
                </Stack>
                {false && (
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      defaultValue="line"
                      onChange={handleChangeChart}
                    >
                      <FormControlLabel
                        value="line"
                        control={<Radio />}
                        label={t('lineChart')}
                      />
                      <FormControlLabel
                        value="diag"
                        control={<Radio />}
                        label={t('diagramma')}
                      />
                      <FormControlLabel
                        value="area"
                        control={<Radio />}
                        label={t('areaChart')}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </Stack>
              <Divider />
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ color: MAIN_COLOR_STRING }}
                      checked={checkParam.volume}
                      onChange={handleChangeVolume}
                    />
                  }
                  sx={{
                    color: '#0288d1',
                  }}
                  label={t('volumeChart')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ color: MAIN_COLOR_STRING }}
                      checked={checkParam.volumeSum}
                      onChange={handleChangeVolumeSum}
                    />
                  }
                  sx={{
                    color: '#00796b',
                  }}
                  label={t('volumeSumChart')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ color: MAIN_COLOR_STRING }}
                      checked={checkParam.temp}
                      onChange={handleChangeTemp}
                    />
                  }
                  sx={{
                    color: '#e64a19',
                  }}
                  label={t('tempChart')}
                />
              </Stack>
              <Stack height="580px">
                {chartType === 'line' && (
                  <ResponsiveContainer width="100%">
                    <LineChart
                      data={
                        period === 'date' ? hourArchiveDtos : dayArchiveDtos
                      }
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        height={40}
                        dataKey="datetime"
                        tick={{ fontSize: 10 }}
                      >
                        <Label
                          value={t('dateTimeChart')}
                          position="outside"
                          fontSize={14}
                        />
                      </XAxis>
                      {checkParam.volume && (
                        <YAxis
                          domain={[dpLimit[0], dpLimit[1]]}
                          width={80}
                          yAxisId="left"
                          tick={{ fontSize: 10 }}
                        >
                          <Label
                            value={t('volumeChart')}
                            angle={-90}
                            position="outside"
                            fill="#0288d1"
                            fontSize={14}
                          />
                        </YAxis>
                      )}
                      {checkParam.volumeSum && (
                        <YAxis
                          domain={[dpLimit[4], dpLimit[5]]}
                          width={80}
                          yAxisId="left2"
                          orientation="left"
                          tick={{ fontSize: 10 }}
                        >
                          <Label
                            value={t('volumeSumChart')}
                            angle={-90}
                            position="outside"
                            fill="#00796b"
                            fontSize={14}
                          />
                        </YAxis>
                      )}
                      {checkParam.temp && (
                        <YAxis
                          domain={[dpLimit[2], dpLimit[3]]}
                          width={80}
                          yAxisId="right"
                          orientation="right"
                          tick={{ fontSize: 10 }}
                        >
                          <Label
                            value={t('tempChart')}
                            angle={-90}
                            position="outside"
                            fill="#e64a19"
                            fontSize={14}
                          />
                        </YAxis>
                      )}
                      <Tooltip />
                      {checkParam.volume && (
                        <Line
                          yAxisId="left"
                          name={t('volumeChart')}
                          type="monotone"
                          dataKey="volumeSu"
                          stroke="#3f51b5"
                        />
                      )}
                      {checkParam.volumeSum && (
                        <Line
                          yAxisId="left2"
                          name={t('volumeSumChart')}
                          type="monotone"
                          dataKey="volumeSuSum"
                          stroke="#00796b"
                        >
                          {false && (
                            <LabelList
                              dataKey="volumeSuSum"
                              angle={-90}
                              offset={1}
                              position="insideTopLeft"
                            />
                          )}
                        </Line>
                      )}
                      {checkParam.temp && (
                        <Line
                          yAxisId="right"
                          name={t('tempChart')}
                          type="monotone"
                          dataKey="temp"
                          stroke="#e64a19"
                        />
                      )}
                    </LineChart>
                  </ResponsiveContainer>
                )}
                {chartType === 'diag' && (
                  <ResponsiveContainer width="100%">
                    <BarChart
                      data={
                        period === 'date' ? hourArchiveDtos : dayArchiveDtos
                      }
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="5 5" />
                      <XAxis dataKey="datetime" />
                      <YAxis yAxisId="y1" domain={[dpLimit[0], dpLimit[1]]}>
                        <Label
                          value={t('volumeChart')}
                          angle={-90}
                          position="outside"
                          fill="#676767"
                          fontSize={14}
                        />
                      </YAxis>
                      <YAxis
                        yAxisId="y2"
                        domain={[dpLimit[2], dpLimit[3]]}
                        orientation="right"
                      >
                        <Label
                          value={t('tempChart')}
                          angle={-90}
                          position="outside"
                          fill="#676767"
                          fontSize={14}
                        />
                      </YAxis>
                      <Tooltip />
                      <Legend />
                      {checkParam.volume && (
                        <Bar
                          yAxisId="y1"
                          name={t('volumeChart')}
                          dataKey="volumeSu"
                          fill="#3f51b5"
                        />
                      )}
                      {checkParam.temp && (
                        <Bar
                          yAxisId="y2"
                          name={t('tempChart')}
                          dataKey="temp"
                          fill="#f44336"
                        />
                      )}
                    </BarChart>
                  </ResponsiveContainer>
                )}
                {chartType === 'area' && (
                  <ResponsiveContainer width="100%">
                    <AreaChart
                      width={600}
                      height={600}
                      data={
                        period === 'date' ? hourArchiveDtos : dayArchiveDtos
                      }
                    >
                      <YAxis yAxisId="y1" domain={[dpLimit[0], dpLimit[1]]}>
                        <Label
                          value={t('volumeChart')}
                          angle={-90}
                          position="outside"
                          fill="#676767"
                          fontSize={14}
                        />
                      </YAxis>
                      <YAxis
                        yAxisId="y2"
                        domain={[dpLimit[2], dpLimit[3]]}
                        orientation="right"
                      >
                        <Label
                          value={t('tempChart')}
                          angle={-90}
                          position="outside"
                          fill="#676767"
                          fontSize={14}
                        />
                      </YAxis>
                      {checkParam.volume && (
                        <Area
                          yAxisId="y1"
                          name={t('volumeChart')}
                          fill="#3f51b5"
                          dataKey="volumeSu"
                          stroke="#3f51b5"
                        />
                      )}
                      {checkParam.temp && (
                        <Area
                          yAxisId="y2"
                          name={t('tempChart')}
                          fill="#f44336"
                          dataKey="temp"
                          stroke="#f44336"
                        />
                      )}
                      <CartesianGrid stroke="#ccc" />
                      <XAxis dataKey="datetime" />
                      <YAxis />
                    </AreaChart>
                  </ResponsiveContainer>
                )}
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </div>
  );
};
