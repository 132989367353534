import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { AccountDto } from '../../dto/AccountDto';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiUpdateLink = async (dto: AccountDto) => {
  const params: IApiParams = {
    url: '/link-flowmeter/updateLink.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', dto);
};
