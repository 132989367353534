import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { MainPageLayout } from '../layout/MainPageLayout';
import { AppTokenDiv } from '../component/app-token/AppTokenDiv';

export const AppTokenPage = () => (
  <MainPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<AppTokenDiv />}
  />
);
