import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { MainPageLayout } from '../layout/MainPageLayout';
import { LogDiv } from '../component/log/LogDiv';

export const LogPage = () => (
  <MainPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<LogDiv />}
  />
);
