import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  deviceType,
  getDefaultAlertProps,
  getDescrPlombNum,
  getIdArray,
  getKategoryArray,
  getKategoryDescription,
  getNameArray,
  getRootLevel,
  getTooltipErrors,
  getViewType,
  replaceQuoat,
  roundValue,
  unixToLocal,
  workTime,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  LEVEL_READ,
  MAIN_COLOR_STRING,
  MAX_GMT,
  MAX_HOUR,
  MIN_GMT,
  MIN_HOUR,
  NEO_REG_NUM,
  PC_VIEW,
  ROUND_BATTERY,
  ROUND_KOMP,
  ROUND_PRES,
  ROUND_TEMP,
  ROUND_TEMP_PROC,
  ROUND_VOLUME_ALL,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { MyTextFiled } from '../UI/MyTextField';
import { DialogInfoItem } from './DeviceInfoFlowmeterItem';
import { DeviceInfoDto } from '../../dto/DeviceInfoDto';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiGetInfo } from '../../service/info/apiGetInfo';
import { GroupDto } from '../../dto/GroupDto';
import { apiGetGroups } from '../../service/group/apiGetGroups';
import { apiUpdateInfo } from '../../service/info/apiUpdateInfo';
import { apiGetVerificationDate } from '../../service/info/apiGetVerificationDate';
import { MyDateTextField } from '../UI/MyDateTextField';
import { apiGetArshin } from '../../service/info/apiGetArshin';

export const DeviceInfoDiv = () => {
  const { id } = useParams();
  const root = getRootLevel();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const kategoryTypes = getKategoryArray();
  const { t } = useTranslation();
  const [checkHelper, setCheckHelper] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [groupDtos, setGroupDtos] = useState<GroupDto[]>([]);
  const [deviceInfoDto, setDeviceDto] = useState<DeviceInfoDto>();

  const getInfo = async (idDevice: string) => {
    const apiResult: IApiResult = await apiGetInfo(idDevice);
    if (apiResult.isSuccess) {
      setDeviceDto(apiResult.data.info);
      document.title = `${t('flowMeter')} № ${apiResult.data.info.deviceNum}`;
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getGroups = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetGroups();
    if (apiResult.isSuccess) {
      setGroupDtos(apiResult.data.groups);
      await getInfo(id ?? '');
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getArshin = async (idDevice: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetArshin(idDevice);
    if (apiResult.isSuccess) {
      await getInfo(id ?? '');
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getVerificationDate = async (year: string, search: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetVerificationDate(year, search);
    if (apiResult.isSuccess) {
      setDeviceDto(
        deviceInfoDto && {
          ...deviceInfoDto,
          dateSert: apiResult.data.result.items.valid_date,
        }
      );
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const updateInfo = async () => {
    const apiResult: IApiResult = await apiUpdateInfo(deviceInfoDto);
    if (apiResult.isSuccess) {
      setIsEdit(false);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleUpdate = async () => {
    if (
      deviceInfoDto?.gmt.toString() === '' ||
      deviceInfoDto?.connectTime.toString() === ''
    ) {
      setCheckHelper(true);
      return;
    }
    setCheckHelper(false);
    await updateInfo();
  };

  // `${NEO_REG_NUM} *${deviceInfoDto?.deviceNum}*`

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv
            title={t('info')}
            isFlowmeter
            deviceInfo={
              deviceInfoDto &&
              `${t('flowMeter')} № ${deviceInfoDto?.deviceNum} ${replaceQuoat(
                deviceInfoDto?.holder,
                0
              )}`
            }
          />
        </Stack>
        <Stack direction="column" width="90%" spacing={2}>
          <Stack
            direction={getViewType() !== PC_VIEW ? 'column' : 'row'}
            spacing={2}
          >
            <Card
              style={{
                padding: '20px',
                width: getViewType() !== PC_VIEW ? undefined : '70%',
              }}
            >
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Stack
                    width="100%"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                  >
                    <Stack>
                      {root !== LEVEL_READ && (
                        <IconButton
                          title={t('tooltipEdit')}
                          size="medium"
                          onClick={() => setIsEdit(!isEdit)}
                        >
                          <EditIcon fontSize="medium" sx={{ color: 'red' }} />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                  <Stack>
                    <IconButton
                      disabled={!isEdit}
                      title={t('tooltipSave')}
                      size="medium"
                      onClick={handleUpdate}
                    >
                      <SaveIcon
                        fontSize="medium"
                        sx={{ color: isEdit ? MAIN_COLOR_STRING : 'gray' }}
                      />
                    </IconButton>
                  </Stack>
                </Stack>
                <Divider />
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.holder, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            holder: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      disabled={!isEdit}
                      type="text"
                      label={t('holder')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.contract, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            contract: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      disabled={!isEdit}
                      type="text"
                      label={t('contract')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.inn}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            inn: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      type="text"
                      label={t('inn')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.persAcc}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            persAcc: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      type="text"
                      label={t('persAcc')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.location, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            location: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      disabled={!isEdit}
                      type="text"
                      label={t('address')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="center"
                      spacing="1"
                    >
                      <MyDateTextField
                        type="date"
                        value={deviceInfoDto?.dateSert}
                        onChange={(e) =>
                          setDeviceDto(
                            deviceInfoDto && {
                              ...deviceInfoDto,
                              dateSert: e.target.value,
                            }
                          )
                        }
                        disabled={!isEdit}
                        label={t('sertDate')}
                        size="small"
                      />
                      <Stack>
                        <IconButton
                          disabled={!isEdit}
                          onClick={() => getArshin(id ?? '')}
                          title={t('tooltipArshin')}
                        >
                          <ContentPasteSearchIcon
                            sx={{ color: isEdit ? MAIN_COLOR_STRING : 'gray' }}
                          />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.gmt}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            gmt: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="number"
                      label={t('gmt')}
                      min={MIN_GMT}
                      max={MAX_GMT}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.connectTime}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            connectTime: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="number"
                      label={t('connectTime')}
                      min={MIN_HOUR}
                      max={MAX_HOUR}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyAutoComplete
                      maxHeightDropList="400px"
                      value={
                        deviceInfoDto?.kategNum !== undefined &&
                        deviceInfoDto?.kategNum !== -1
                          ? getKategoryDescription(deviceInfoDto?.kategNum)
                          : ''
                      }
                      disabled={!isEdit}
                      onChange={(e, v) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            kategNum: kategoryTypes.indexOf(v),
                          }
                        )
                      }
                      arrayList={kategoryTypes}
                      size="small"
                      label={t('kategory')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyAutoComplete
                      maxHeightDropList="400px"
                      value={
                        deviceInfoDto?.grupNum !== ''
                          ? getNameArray(groupDtos)[
                              Object.values(getIdArray(groupDtos)).indexOf(
                                deviceInfoDto?.grupNum
                              )
                            ]
                          : ''
                      }
                      disabled={!isEdit}
                      onChange={(e, v) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            grupNum:
                              getIdArray(groupDtos)[
                                Object.values(getNameArray(groupDtos)).indexOf(
                                  v
                                )
                              ],
                          }
                        )
                      }
                      arrayList={getNameArray(groupDtos)}
                      size="small"
                      label={t('group')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.phone}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            phone: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      type="text"
                      label={t('phone')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.email}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            email: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      type="text"
                      label={t('email')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.flowmeterGroupId}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            flowmeterGroupId: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      type="text"
                      label={t('flowmeterGroupId')}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Card>
            <Card
              style={{
                padding: '20px',
                width: getViewType() !== PC_VIEW ? undefined : '30%',
              }}
            >
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Typography
                    style={{
                      fontFamily: 'sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      color: '#000000',
                      letterSpacing: 1,
                      cursor: 'pointer',
                    }}
                    component="text"
                  >
                    {t('infoDevice')}
                  </Typography>
                </Stack>
                <Divider />
                <DialogInfoItem
                  param={t('deviceType')}
                  value={deviceType(deviceInfoDto?.deviceType)}
                />
                <DialogInfoItem
                  param={t('deviceNum')}
                  value={deviceInfoDto?.deviceNum}
                />
                <DialogInfoItem
                  param={t('tempProc')}
                  value={roundValue(
                    deviceInfoDto?.tempProc,
                    ROUND_TEMP_PROC,
                    true
                  )}
                />
                <DialogInfoItem
                  param={t('timeLeft')}
                  value={roundValue(
                    deviceInfoDto?.timeLeft,
                    ROUND_BATTERY,
                    true
                  )}
                />
                <Stack
                  direction="row"
                  paddingTop="10px"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Typography
                    style={{
                      fontFamily: 'sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      color: '#000000',
                      letterSpacing: 1,
                      cursor: 'pointer',
                    }}
                    component="text"
                  >
                    {t('flowMeterValues')}
                  </Typography>
                </Stack>
                <Divider />
                <DialogInfoItem
                  param={t('temp')}
                  value={roundValue(deviceInfoDto?.temp, ROUND_TEMP, true)}
                />
                <DialogInfoItem
                  param={t('pres')}
                  value={roundValue(deviceInfoDto?.pres, ROUND_PRES, true)}
                />
                <DialogInfoItem
                  param={t('komp')}
                  value={roundValue(deviceInfoDto?.komp, ROUND_KOMP, true)}
                />
                <DialogInfoItem
                  param={t('sumVolSt')}
                  value={roundValue(
                    deviceInfoDto?.volumeSuSum,
                    ROUND_VOLUME_ALL,
                    true
                  )}
                />
                <DialogInfoItem
                  param={t('nowErrors')}
                  color={deviceInfoDto?.events !== '0' ? '#ef5350' : undefined}
                  tooltip={getTooltipErrors(deviceInfoDto?.events)}
                  value={
                    deviceInfoDto?.events !== '0'
                      ? deviceInfoDto?.events
                      : t('noError')
                  }
                />
                <DialogInfoItem
                  param={t('memmoryErrors')}
                  color={
                    deviceInfoDto?.eventsMemmory !== '0' ? '#ef5350' : undefined
                  }
                  tooltip={getTooltipErrors(deviceInfoDto?.eventsMemmory)}
                  value={
                    deviceInfoDto?.eventsMemmory !== '0'
                      ? deviceInfoDto?.eventsMemmory
                      : t('noError')
                  }
                />
                <DialogInfoItem
                  param={t('datetime')}
                  value={deviceInfoDto?.deviceTime}
                />
                <DialogInfoItem
                  param={t('plombNum')}
                  color={
                    deviceInfoDto?.plombNum === '0' ? '#ef5350' : undefined
                  }
                  value={getDescrPlombNum(deviceInfoDto?.plombNum)}
                />
                <Stack
                  direction="row"
                  paddingTop="10px"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Typography
                    style={{
                      fontFamily: 'sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      color: '#000000',
                      letterSpacing: 1,
                      cursor: 'pointer',
                    }}
                    component="text"
                  >
                    {t('stats')}
                  </Typography>
                </Stack>
                <Divider />
                <DialogInfoItem
                  param={t('timeTotal')}
                  value={workTime(deviceInfoDto?.timeTotal)}
                />
                <DialogInfoItem
                  param={t('timeNewBat')}
                  value={workTime(deviceInfoDto?.timeNewBat)}
                />
                <Stack
                  direction="row"
                  paddingTop="10px"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Typography
                    style={{
                      fontFamily: 'sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      color: '#000000',
                      letterSpacing: 1,
                      cursor: 'pointer',
                    }}
                    component="text"
                  >
                    {t('connect')}
                  </Typography>
                </Stack>
                <Divider />
                <DialogInfoItem
                  param={t('dateCreate')}
                  value={unixToLocal(deviceInfoDto?.dateCreate)}
                />
                <DialogInfoItem
                  param={t('dateLastCon')}
                  value={unixToLocal(deviceInfoDto?.dateLastCon)}
                />
                <DialogInfoItem
                  param={t('dateNextCon')}
                  color={deviceInfoDto?.isConnect === 1 ? '#ef5350' : undefined}
                  value={unixToLocal(deviceInfoDto?.dateNextCon)}
                />
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
