import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import Logo from '../../image/logo.png';
import { FACTORY_ROUTE, SIGN_IN_ROUTE } from '../../constant/routes';
import { getDefaultAlertProps } from '../../utils/utils';
import { IAlertProps } from '../../interface/IAlertProps';
import { MyAlert } from '../UI/MyAlert';

export const FactoryNavigationBar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const darkTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#212121',
      },
    },
  });

  const handleLogout = async () => {
    navigate(SIGN_IN_ROUTE);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Toolbar>
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    onClick={() => navigate(FACTORY_ROUTE)}
                  >
                    <img alt="" src={Logo} style={{ cursor: 'pointer' }} />
                    <Stack direction="row" alignItems="center" spacing={0}>
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'revert',
                          fontWeight: 750,
                          fontSize: '28px',
                          color: '#FFFFFF',
                        }}
                        component="text"
                      >
                        Про
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'normal',
                          fontWeight: 100,
                          fontSize: '28px',
                          color: '#FFFFFF',
                        }}
                        component="text"
                      >
                        НЕО
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                  size="large"
                  title={t('tooltipExit')}
                  edge="start"
                  color="inherit"
                  onClick={handleLogout}
                  aria-label="menu"
                >
                  <LogoutIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
};
