export const MAIN_COLOR_STRING = '#0091ea';
export const MOBILE_VIEW = 0;
export const PAD_VIEW = 1;
export const PC_VIEW = 2;
export const DEVICE_STATUS = 'device';
export const STATE = 'state';
export const ACCESS_TOKEN_NAME = 'access';
export const TOKEN_NAME = 'token';
export const IP_LOCAL_NAME = 'iplocal';
export const REFRESH_TOKEN_NAME = 'refresh';
export const HOLDER_NAME = 'holder';
export const FULLNAME_NAME = 'name';
export const ROOT_NAME = 'root';
export const HASH_NAME = 'hash';
export const JSON_CONTENT_TYPE = 'application/json';
export const FORM_DATA_CONTENT_TYPE = 'form-data';
export const WARNING_DATA_CONTENT_TYPE = 'warning-data';
export const API_REQUEST_TIMEOUT_MILLISECONDS = 3000;
export const API_REQUEST_TIMEOUT_MILLISECONDS_ERROR_STATUS = -10;
export const VERSION = '1.0';
export const LANGUAGE_RUS = 'rus';
export const LANGUAGE_EN = 'en';
export const ACTION_MAGNET_FLOW = 0;
export const ACTION_TEMP = 1;
export const START_PAGE_SIZE_DEVICES = 20;
export const PAGE_SIZE_DEVICES = [20, 50, 100];
export const START_PAGE_SIZE_ARCHIVES = 15;
export const PAGE_SIZE_ARCHIVES = [15, 50, 100];

export const LEVEL_READ = 1;
export const LEVEL_READ_WRITE = 2;
export const LEVEL_ADMIN = 5;

export const VIEW_ARCHIVE = '0';
export const PDF_ARCHIVE = '1';
export const EXCEL_ARCHIVE = '2';

export const NEO_REG_NUM = '*92260-24*';

export const ROUND_FLOW = 1000;
export const ROUND_VOLUME = 10000;
export const ROUND_VOLUME_ALL = 10000;
export const ROUND_TEMP = 100;
export const ROUND_PRES = 1000;
export const ROUND_KOMP = 10000;
export const ROUND_TIME_HOUR = 100;
export const ROUND_TEMP_PROC = 100;
export const ROUND_VOLT = 100;
export const ROUND_BATTERY = 10;

export const MIN_CONST_PRES_TASK = 100;
export const MAX_CONST_PRES_TASK = 157;

export const MIN_CONST_KOMP_TASK = 0.9;
export const MAX_CONST_KOMP_TASK = 1.1;

export const MIN_CONST_TEMP_TASK = -30;
export const MAX_CONST_TEMP_TASK = 60;

export const MIN_CONTROL_TEMP_TASK = -30;
export const MAX_CONTROL_TEMP_TASK = 60;

export const MIN_CONST_FLOW_TASK = 0;
export const MAX_CONST_FLOW_TASK = 400;

export const MIN_CONTR_HOUR_TASK = 0;
export const MAX_CONTR_HOUR_TASK = 23;

export const MIN_CONTR_DAY_TASK = 1;
export const MAX_CONTR_DAY_TASK = 31;

export const MIN_MIN_SIGN_TASK = 0;
export const MAX_MAX_SIGN_TASK = 12;

export const MIN_TEMP_GPRS_TASK = -30;
export const MAX_TEMP_GPRS_TASK = 0;

export const MIN_GMT = 0;
export const MAX_GMT = 23;

export const MIN_HOUR = 0;
export const MAX_HOUR = 23;
