import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  createTheme,
  IconButton,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
} from '@mui/material';
import {
  getDefaultAlertProps,
  getSelectedLng,
  getViewType,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  PAGE_SIZE_DEVICES,
  PC_VIEW,
  START_PAGE_SIZE_DEVICES,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { AppIdDto } from '../../dto/AppIdDto';
import { TokenDto } from '../../dto/TokenDto';
import { apiGetAppId } from '../../service/token/apiGetAppId';
import { apiGetTokens } from '../../service/token/apiGetTokens';
import { apiDeleteAppId } from '../../service/token/apiDeleteAppId';
import { apiDeleteToken } from '../../service/token/apiDeleteToken';
import { MyTextFiled } from '../UI/MyTextField';
import { apiAddApId } from '../../service/token/apiAddAppId';
import { apiAddToken } from '../../service/token/apiAddToken';
import { FactoryBodyHeaderDiv } from '../body-header/FactoryBodyHeaderDiv';
import { apiGetServiceAppId } from '../../service/service-token/apiGetServiceAppId';
import { apiGetServiceTokens } from '../../service/service-token/apiGetServiceTokens';
import { apiDeleteServiceAppId } from '../../service/service-token/apiDeleteServiceAppId';
import { apiAddServiceApId } from '../../service/service-token/apiAddServiceAppId';
import { apiDeleteServiceToken } from '../../service/service-token/apiDeleteServiceToken';
import { apiAddServiceToken } from '../../service/service-token/apiAddServiceToken';

export const ServiceTokenDiv = () => {
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [appIdDto, setAppIdDto] = useState<AppIdDto>({
    id: -1,
    appId: '',
    name: '',
    organization: '',
    createDate: -1,
  });
  const [appIdDtos, setAppIdDtos] = useState<AppIdDto[]>([]);
  const [tokenDtos, setTokenDtos] = useState<TokenDto[]>([]);

  const getAppId = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetServiceAppId();
    if (apiResult.isSuccess) {
      setAppIdDtos(apiResult.data.app);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getTokens = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetServiceTokens();
    if (apiResult.isSuccess) {
      setTokenDtos(apiResult.data.tokens);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const deleteAppId = async (dto: AppIdDto) => {
    const apiResult: IApiResult = await apiDeleteServiceAppId(dto);
    if (apiResult.isSuccess) {
      getAppId();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const addAppId = async (dto: AppIdDto) => {
    const apiResult: IApiResult = await apiAddServiceApId(dto);
    if (apiResult.isSuccess) {
      getAppId();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const deleteToken = async (dto: TokenDto) => {
    const apiResult: IApiResult = await apiDeleteServiceToken(dto);
    if (apiResult.isSuccess) {
      getTokens();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const addToken = async (dto: AppIdDto) => {
    const apiResult: IApiResult = await apiAddServiceToken(dto);
    if (apiResult.isSuccess) {
      getTokens();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [checkHelper, setCheckHelper] = useState(false);

  const handelCreate = async () => {
    if (appIdDto?.appId === '' || appIdDto?.name === '') {
      setCheckHelper(true);
      return;
    }
    await addAppId(appIdDto);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const [value, setValue] = React.useState('one');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 'one') {
      getAppId();
    } else {
      getTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const columnsAppId: GridColDef[] = [
    {
      field: 'appId',
      headerName: t('headerTokenAppId'),
      renderHeader: () => <strong>{t('headerTokenAppId')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.appId,
    },
    {
      field: 'name',
      headerName: t('headerTokenName'),
      renderHeader: () => <strong>{t('headerTokenName')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'organization',
      headerName: t('headerTokenOrganization'),
      renderHeader: () => <strong>{t('headerTokenOrganization')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.organization,
    },
    {
      field: 'id',
      type: 'actions',
      width: 70,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            title={t('tooltipAddToken')}
            onClick={() => addToken(row.row)}
          >
            <AddBoxIcon
              fontSize="small"
              sx={{
                color: MAIN_COLOR_STRING,
              }}
            />
          </IconButton>
          <IconButton
            size="small"
            title={t('tooltipDelete')}
            onClick={() => deleteAppId(row.row)}
          >
            <DeleteForeverIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsToken: GridColDef[] = [
    {
      field: 'name',
      headerName: t('headerTokenName'),
      renderHeader: () => <strong>{t('headerTokenName')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'token',
      headerName: t('headerTokenId'),
      renderHeader: () => <strong>{t('headerTokenId')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => row.row.token,
    },
    {
      field: 'createDate',
      headerName: t('headerTokenCreateDate'),
      renderHeader: () => <strong>{t('headerTokenCreateDate')}</strong>,
      minWidth: 250,
      editable: false,
      renderCell: (row) => unixToLocal(row.row.createDate),
    },
    {
      field: 'validDate',
      headerName: t('headerTokenDateTo'),
      renderHeader: () => <strong>{t('headerTokenDateTo')}</strong>,
      minWidth: 250,
      editable: false,
      renderCell: (row) => unixToLocal(row.row.validDate),
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            title={t('tooltipDelete')}
            onClick={() => deleteToken(row.row)}
          >
            <DeleteForeverIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <FactoryBodyHeaderDiv title={t('serviceTokenMenu')} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Tabs value={value} onChange={handleChange}>
            <Tab value="one" label={t('users')} />
            <Tab value="two" label={t('tokens')} />
          </Tabs>
          {value === 'one' && (
            <Stack direction="row" spacing={1}>
              <MyTextFiled
                value={appIdDto.appId}
                onChange={(e) =>
                  setAppIdDto({
                    ...appIdDto,
                    appId: e.target.value,
                  })
                }
                autoComplete="off"
                size="small"
                error={checkHelper}
                label={t('textTokenAppId')}
              />
              <MyTextFiled
                value={appIdDto.name}
                autoComplete="off"
                onChange={(e) =>
                  setAppIdDto({ ...appIdDto, name: e.target.value })
                }
                size="small"
                error={checkHelper}
                label={t('textTokenName')}
              />
              <MyTextFiled
                value={appIdDto.organization}
                autoComplete="off"
                onChange={(e) =>
                  setAppIdDto({ ...appIdDto, organization: e.target.value })
                }
                size="small"
                error={checkHelper}
                label={t('textTokenOrganization')}
              />
              <Stack alignItems="center">
                <IconButton
                  sx={{ color: MAIN_COLOR_STRING }}
                  onClick={handelCreate}
                >
                  <AddIcon />
                </IconButton>
              </Stack>
            </Stack>
          )}
          <Box
            sx={{
              '& .app-theme-cell': {
                fontFamily: 'sans-serif',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                    fontSize: '100px',
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={value === 'one' ? columnsAppId : columnsToken}
                rows={value === 'one' ? appIdDtos : tokenDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={PAGE_SIZE_DEVICES}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        </Stack>
      </Stack>
    </div>
  );
};
