import { Card, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { FunctionComponent } from 'react';

interface Props {
  title: string;
  onClose: () => void;
}

export const DialogHeaderDiv: FunctionComponent<Props> = ({
  title,
  onClose,
}) => (
  <div>
    <Stack spacing={1}>
      <Card
        style={{
          padding: '5px 20px 5px 20px',
          background: '#9e9e9e',
        }}
      >
        <Stack spacing={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '24px',
                color: '#FFFFFF',
                letterSpacing: 1,
              }}
              component="text"
            >
              {title}
            </Typography>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon fontSize="large" sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  </div>
);
