import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { MainPageLayout } from '../layout/MainPageLayout';
import { ServiceDiv } from '../component/service/ServiceDiv';

export const ServicePage = () => (
  <MainPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<ServiceDiv />}
  />
);
