import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { getApiFile } from '../getApiFile';

export const apiPrintSetup = async (id: string) => {
  const params: IApiParams = {
    url: `/setup/printSetup.php?id=${id}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApiFile(params, 'GET');
};
