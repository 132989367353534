/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Autocomplete, TextField } from '@mui/material';

interface Props {
  label: string;
  arrayList: any;
  disabled?: boolean;
  width?: string;
  size?: any;
  error?: boolean;
  helperText?: string;
  maxHeightDropList?: string;
  value?: string;
  onChange?: (e: any, v: any) => void;
}

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'grey',
  },
});

export const MyAutoComplete: FunctionComponent<Props> = ({
  label,
  arrayList,
  disabled,
  width,
  size,
  error,
  helperText,
  maxHeightDropList,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      value={value}
      disabled={disabled}
      onChange={onChange}
      style={{ width }}
      options={arrayList}
      ListboxProps={{
        style: {
          maxHeight: maxHeightDropList,
        },
      }}
      renderInput={(params) => (
        <CssTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={label}
          disabled={disabled}
          error={error && value === ''}
          variant="filled"
          style={{
            fontFamily: 'sans-serif',
          }}
          helperText={error && value === '' ? t('enterValue') : helperText}
          size={size}
        />
      )}
    />
  );
};

MyAutoComplete.defaultProps = {
  value: '',
  width: '100%',
  size: 'large',
  error: false,
  helperText: '',
  maxHeightDropList: '200px',
  disabled: false,
  onChange: undefined,
};
