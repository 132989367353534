import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiLogout = async () => {
  const params: IApiParams = {
    url: '/account/logout.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'POST');
};
