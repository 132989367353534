import { Card, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import React, { FunctionComponent } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FULLNAME_NAME, MOBILE_VIEW, PC_VIEW } from '../../constant/constants';
import {
  getRootDescription,
  getRootLevel,
  getViewType,
  isMobileView,
} from '../../utils/utils';
import { SpeedDialDiv } from '../speed-dial/SpeedDialDiv';
import { SpeedDiaFlowmeterlDiv } from '../speed-dial/SpeedDialFlowmeterDiv';

interface Props {
  title: string;
  isFlowmeter?: boolean;
  deviceInfo?: string;
}

export const BodyHeaderDiv: FunctionComponent<Props> = ({
  title,
  isFlowmeter,
  deviceInfo,
}) => {
  const location = useLocation();
  const root = getRootLevel();
  const { t } = useTranslation();

  return (
    <div>
      <Stack spacing={2}>
        <Card
          style={{
            padding:
              getViewType() === PC_VIEW
                ? '15px 25px 15px 25px'
                : '15px 15px 15px 15px',
            marginTop: '20px',
            background: '#9e9e9e',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                {location.pathname !== '/main' &&
                  getViewType() !== MOBILE_VIEW &&
                  !isFlowmeter && (
                    <IconButton
                      title={t('tooltipBack')}
                      size="large"
                      sx={{ color: '#ffFFFF' }}
                      onClick={() => window.history.back()}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  )}
                {isFlowmeter && <SpeedDiaFlowmeterlDiv />}
                {getViewType() !== MOBILE_VIEW && (
                  <Typography
                    style={{
                      fontFamily: 'sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: getViewType() === PC_VIEW ? '24px' : '14px',
                      color: '#FFFFFF',
                      letterSpacing: 1,
                    }}
                    component="text"
                  >
                    {title}
                  </Typography>
                )}
                {getViewType() === MOBILE_VIEW && !isFlowmeter && (
                  <Typography
                    style={{
                      fontFamily: 'sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: getViewType() === PC_VIEW ? '24px' : '14px',
                      color: '#FFFFFF',
                      letterSpacing: 1,
                    }}
                    component="text"
                  >
                    {title}
                  </Typography>
                )}
                {getViewType() !== MOBILE_VIEW && !isFlowmeter && (
                  <SpeedDialDiv />
                )}
              </Stack>
            </Stack>
            {getViewType() !== MOBILE_VIEW && isFlowmeter && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: getViewType() === PC_VIEW ? '20px' : '14px',
                    color: '#FFFFFF',
                    letterSpacing: 1,
                  }}
                  component="text"
                >
                  {deviceInfo}
                </Typography>
              </Stack>
            )}
            {getViewType() === PC_VIEW && !isFlowmeter && (
              <Stack direction="column" alignItems="end" spacing={1}>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: getViewType() === PC_VIEW ? '18px' : '14px',
                    color: '#FFFFFF',
                  }}
                  component="text"
                >
                  {`${localStorage.getItem(FULLNAME_NAME)}`}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: getViewType() === PC_VIEW ? '16px' : '14px',
                    color: '#FFFFFF',
                  }}
                  component="text"
                >
                  {`${getRootDescription(root)}`}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Card>
        <Divider />
      </Stack>
    </div>
  );
};

BodyHeaderDiv.defaultProps = {
  isFlowmeter: false,
  deviceInfo: '',
};
