import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StorageIcon from '@mui/icons-material/Storage';
import TaskIcon from '@mui/icons-material/Task';
import WarningIcon from '@mui/icons-material/Warning';
import SignalCellularNodataIcon from '@mui/icons-material/SignalCellularNodata';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import GpsOffIcon from '@mui/icons-material/GpsOff';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArticleIcon from '@mui/icons-material/Article';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import { getDefaultAlertProps, getRootLevel } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import { MainDivGridItem } from './MainDivGridItem';
import { MainStatDto } from '../../dto/MainStatDto';
import { DeviceStageEnum } from '../../enum/DeviceStageEnum';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetStats } from '../../service/device/apiGetStats';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { LEVEL_ADMIN } from '../../constant/constants';

export const MainDiv = () => {
  const root = getRootLevel();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [mainStatsDto, setMainStats] = useState<MainStatDto>();

  const getStats = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetStats();
    if (apiResult.isSuccess) {
      setMainStats(apiResult.data.stats);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    document.title = 'ПроНЕО';
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv title={t('dashboard')} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Grid container spacing={1}>
            <MainDivGridItem
              image={StorageIcon}
              color="#29b6f6"
              param={DeviceStageEnum.ALL}
              value={mainStatsDto?.cntSystem}
            />
            <MainDivGridItem
              image={TaskIcon}
              color="#26a69a"
              param={DeviceStageEnum.TASK}
              value={mainStatsDto?.cntTask}
            />
            <MainDivGridItem
              image={WarningIcon}
              color="#ef5350"
              param={DeviceStageEnum.WARNING}
              value={mainStatsDto?.cntWarning}
            />
            <MainDivGridItem
              image={SignalCellularNodataIcon}
              color="#ff7043"
              param={DeviceStageEnum.CONNECT}
              value={mainStatsDto?.cntConnect}
            />
            <MainDivGridItem
              image={BatteryAlertIcon}
              color="#ffca28"
              param={DeviceStageEnum.BATTERY}
              value={mainStatsDto?.cntBattery}
            />
            <MainDivGridItem
              image={GpsOffIcon}
              color="#f44336"
              param={DeviceStageEnum.VALVE}
              value={mainStatsDto?.cntValve}
            />
            <MainDivGridItem
              image={ArticleIcon}
              color="#26c6da"
              param={DeviceStageEnum.SERTIFICATE}
              value={mainStatsDto?.cntSert}
            />
            <MainDivGridItem
              image={SystemUpdateIcon}
              color="#ab47bc"
              param={DeviceStageEnum.UPDATE}
              value={mainStatsDto?.cntUpdate}
            />
            <MainDivGridItem
              image={InventoryIcon}
              color="#9ccc65"
              param={DeviceStageEnum.ARCHIVE}
              value={mainStatsDto?.cntArchive}
            />
          </Grid>
        </Stack>
      </Stack>
    </div>
  );
};
