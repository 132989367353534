import {
  JSON_CONTENT_TYPE,
  TOKEN_NAME,
  VIEW_ARCHIVE,
} from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';
import { getApiFile } from '../getApiFile';

export const apiGetDays = async (
  id: string,
  print: string,
  datetime?: string
) => {
  const params: IApiParams = {
    url:
      datetime === undefined
        ? `/archive/getDays.php?id=${id}&print=${print}`
        : `/archive/getDays.php?id=${id}&print=${print}&datetime=${datetime}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  if (print === VIEW_ARCHIVE) {
    return getApi(params, 'GET');
  }
  return getApiFile(params, 'GET');
};
