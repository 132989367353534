import React, { FunctionComponent, useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  getDefaultAlertProps,
  getSelectedLng,
  otherToDataGrid,
} from '../../utils/utils';
import { LANGUAGE_RUS, MAIN_COLOR_STRING } from '../../constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { MyTextFiled } from '../UI/MyTextField';
import { DialogConfirmation } from '../confirmation/DialogConfirmation';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { TelegramDto } from '../../dto/TelegramDto';
import { apiGetTelegrams } from '../../service/telegram/apiGetTelegrams';
import { apiAddTelegram } from '../../service/telegram/apiAddTelegram';
import { apiDeleteTelegram } from '../../service/telegram/apiDeleteTelegram';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogTelegram: FunctionComponent<Props> = ({ open, onClose }) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [telegramDeleteDto, setTelegramDeleteDto] = useState<TelegramDto>({
    id: -1,
    telegramId: '',
    name: '',
  });
  const [telegramDto, setTelegramDto] = useState<TelegramDto>({
    id: -1,
    telegramId: '',
    name: '',
  });
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [telegramDtos, setTelegramDtos] = useState<TelegramDto[]>([]);
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);

  const [checkHelper, setCheckHelper] = useState(false);

  const getTelegrams = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetTelegrams();
    if (apiResult.isSuccess) {
      setTelegramDtos(apiResult.data.users);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const addTelegram = async (dto: TelegramDto) => {
    const apiResult: IApiResult = await apiAddTelegram(dto);
    if (apiResult.isSuccess) {
      setTelegramDto({
        id: -1,
        telegramId: '',
        name: '',
      });
      getTelegrams();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (open) {
      getTelegrams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handelCreate = async () => {
    if (telegramDto.telegramId === '' || telegramDto.name === '') {
      setCheckHelper(true);
      return;
    }
    await addTelegram(telegramDto);
  };

  const handleClose = () => {
    onClose();
    setTelegramDto({
      id: -1,
      telegramId: '',
      name: '',
    });
    setCheckHelper(false);
  };

  const deleteTelegram = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const apiResult: IApiResult = await apiDeleteTelegram(telegramDeleteDto);
    if (apiResult.isSuccess) {
      setTelegramDeleteDto({
        id: -1,
        telegramId: '',
        name: '',
      });
      getTelegrams();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: TelegramDto) => {
    setTelegramDeleteDto(dto);
    setOpenDialogConfirmation(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const columns: GridColDef[] = [
    {
      field: 'telegramId',
      headerName: t('telegramId'),
      renderHeader: () => <strong>{t('telegramId')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.telegramId,
    },
    {
      field: 'name',
      headerName: t('telegramName'),
      renderHeader: () => <strong>{t('telegramName')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton size="small" onClick={() => confirmDelete(row.row)}>
            <DeleteForeverIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', height: '100%', width: '100%' },
        }}
      >
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <BackDropDiv open={backDrop} />
        <DialogTitle>
          <DialogHeaderDiv title={t('telegramId')} onClose={handleClose} />
        </DialogTitle>

        <DialogContent>
          <Stack direction="row" spacing={1}>
            <MyTextFiled
              value={telegramDto?.telegramId}
              autoComplete="off"
              onChange={(e) =>
                setTelegramDto({ ...telegramDto, telegramId: e.target.value })
              }
              size="small"
              error={checkHelper}
              label={t('telegramId')}
            />
            <MyTextFiled
              value={telegramDto?.name}
              onChange={(e) =>
                setTelegramDto({ ...telegramDto, name: e.target.value })
              }
              autoComplete="off"
              size="small"
              error={checkHelper}
              label={t('telegramName')}
            />
            <Stack alignItems="center">
              <IconButton
                sx={{ color: MAIN_COLOR_STRING }}
                onClick={handelCreate}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Stack paddingTop="20px" spacing={1}>
            <Box
              sx={{
                '& .app-theme-cell': {
                  fontFamily: 'sans-serif',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                      fontSize: '100px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '10px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={telegramDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...otherToDataGrid}
                  pageSize={15}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
      <DialogConfirmation
        open={openDialogConfirmation}
        message={t('confirmDeleteUser')}
        onClose={deleteTelegram}
      />
    </div>
  );
};
