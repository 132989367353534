import React from 'react';
import { MainPageLayout } from '../layout/MainPageLayout';
import { FactoryNavigationBar } from '../component/navigation-bar/FactoryNavigationBar';
import { DevicePasswordsDiv } from '../component/device-passwords/DevicePasswordsDiv';

export const DevicePasswordsPage = () => (
  <MainPageLayout
    navigationBar={<FactoryNavigationBar />}
    elementCentr={<DevicePasswordsDiv />}
  />
);
