import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { LocalServerDto } from '../../dto/LocalServerDto';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiAddLocalServer = async (dto?: LocalServerDto) => {
  const params: IApiParams = {
    url: '/localserver/addLocalServer.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', dto);
};
