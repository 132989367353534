import React from 'react';
import { MainPageLayout } from '../layout/MainPageLayout';
import { FactoryNavigationBar } from '../component/navigation-bar/FactoryNavigationBar';
import { FactoryTokenDiv } from '../component/factory-token/FactoryTokenDiv';

export const FactoryTokenPage = () => (
  <MainPageLayout
    navigationBar={<FactoryNavigationBar />}
    elementCentr={<FactoryTokenDiv />}
  />
);
