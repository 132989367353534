import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { AppIdDto } from '../../dto/AppIdDto';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiAddServiceToken = async (dto?: AppIdDto) => {
  const params: IApiParams = {
    url: '/localbase/addServiceToken.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', dto);
};
