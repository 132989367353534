import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { getApiFile } from '../getApiFile';

export const apiPrintQrCode = async (groupId: string) => {
  const params: IApiParams = {
    url: `/bat-qr-code/printQrCode.php?groupId=${groupId}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApiFile(params, 'GET');
};
