import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import classes from './AddFile.module.css';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { IFileUpload } from '../../interface/IFileUpload';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiUploadFile } from '../../service/apiUloadFile';
import { AddFileDto } from '../../dto/AddFileDto';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogUploadArch: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [backDrop] = useState(false);
  const [text, setText] = useState<AddFileDto>({
    text: t('uploadTransportFile'),
    imei: '',
    deviceNum: '',
    deviceType: -1,
    location: '',
    archDay: '',
    archHour: '',
    archMonth: '',
    jourChanges: '',
    jourEvents: '',
    holder: '',
  });

  const handleClose = () => {
    onClose();
    setText({
      text: t('uploadTransportFile'),
      imei: '',
      deviceNum: '',
      deviceType: -1,
      location: '',
      archDay: '',
      archHour: '',
      archMonth: '',
      jourChanges: '',
      jourEvents: '',
      holder: '',
    });
  };

  const uploadFile = async (upload: IFileUpload[]) => {
    if (upload.length !== 0) {
      const apiFetches: any[] = [];
      upload.forEach((item) => {
        apiFetches.push(apiUploadFile(item));
      });
      const apiResults: IApiResult[] = await Promise.all(apiFetches);
      for (let i = 0; i < apiResults.length; i += 1) {
        if (apiResults[i].isSuccess) {
          setText(apiResults[i].data);
        } else {
          setAlertProps({
            message: t('errotServer'),
            severity: AlertSeverityEnum.error,
          });
        }
      }
    }
  };

  const onInputFileChange = async (event: any) => {
    let sizeUploadFiles = 0;
    const dataArray = [];
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i += 1) {
        if (event.target.files[i].size > sizeUploadFiles) {
          sizeUploadFiles = event.target.files[i].size;
        }
        const formData = new FormData();
        formData.append('fileToUpload', event.target.files[i]);
        const dataArrayItem: IFileUpload = {
          data: formData,
          name: event.target.files[i].name,
        };
        dataArray.push(dataArrayItem);
      }
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
    await uploadFile(dataArray);
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', height: '100%', width: '100%' },
        }}
      >
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <BackDropDiv open={backDrop} />
        <DialogTitle>
          <DialogHeaderDiv title={t('uploadArch')} onClose={handleClose} />
        </DialogTitle>

        <DialogContent style={{ height: '100%' }}>
          <Stack spacing={1}>
            {text.text !== undefined && (
              <Stack
                paddingTop="20px"
                direction="row"
                width="100%"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontSize: '28px',
                    wordBreak: 'break-word',
                    color: '#000000',
                  }}
                  component="text"
                >
                  {text.text}
                </Typography>
                <IconButton
                  title="Загрузить"
                  size="small"
                  sx={{ color: '#128DFF' }}
                  className={classes.personalImage}
                >
                  <label htmlFor="inputFile">
                    <Stack direction="row">
                      <input
                        id="inputFile"
                        type="file"
                        onChange={onInputFileChange}
                        multiple={false}
                      />
                      <AddBoxIcon />
                    </Stack>
                  </label>
                </IconButton>
              </Stack>
            )}
            {text.text === undefined && text.deviceNum !== '' && (
              <Stack spacing={1}>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontSize: '14px',
                    wordBreak: 'break-word',
                    color: '#000000',
                  }}
                  component="text"
                >
                  Заводской номер счетчика {text.deviceNum}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontSize: '14px',
                    wordBreak: 'break-word',
                    color: '#000000',
                  }}
                  component="text"
                >
                  Потребитель {text.holder}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontSize: '14px',
                    wordBreak: 'break-word',
                    color: '#000000',
                  }}
                  component="text"
                >
                  {text.archHour}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontSize: '14px',
                    wordBreak: 'break-word',
                    color: '#000000',
                  }}
                  component="text"
                >
                  {text.archDay}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontSize: '14px',
                    wordBreak: 'break-word',
                    color: '#000000',
                  }}
                  component="text"
                >
                  {text.archMonth}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontSize: '14px',
                    wordBreak: 'break-word',
                    color: '#000000',
                  }}
                  component="text"
                >
                  {text.jourChanges}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontSize: '14px',
                    wordBreak: 'break-word',
                    color: '#000000',
                  }}
                  component="text"
                >
                  {text.jourEvents}
                </Typography>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
