import React, { FunctionComponent } from 'react';
import { Stack, Typography } from '@mui/material';
import { getDeviceLicenseDsecr } from '../../utils/utils';

interface Props {
  stage: string;
}

export const DialogItemInfo: FunctionComponent<Props> = ({ stage }) => {
  const items = stage.split('##');
  return (
    <div>
      <Stack justifyContent="space-between" direction="column">
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontSize: '14px',
            fontWeight: 600,
            color: '#000000',
          }}
          component="text"
        >
          {getDeviceLicenseDsecr(items[0])}
        </Typography>
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontSize: '12px',
            color: 'green',
          }}
          component="text"
        >
          {items[1]}
        </Typography>
      </Stack>
    </div>
  );
};
