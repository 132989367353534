import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
} from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {
  getDefaultAlertProps,
  getIdArray,
  getNameArray,
  getSelectedLng,
  getViewType,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  HOLDER_NAME,
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  MOBILE_VIEW,
  PAGE_SIZE_DEVICES,
  PC_VIEW,
  STATE,
} from '../../constant/constants';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DEVICES_INFO_ROUTE } from '../../constant/routes';
import { DialogConfirmation } from '../confirmation/DialogConfirmation';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetDevices } from '../../service/device/apiGetDevices';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { GroupDto } from '../../dto/GroupDto';
import { apiGetGroups } from '../../service/group/apiGetGroups';

export const GroupTaskDiv = () => {
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const { search } = useParams();
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [groupDtos, setGroupDtos] = useState<GroupDto[]>([]);
  const [, setDeviceDto] = useState<DeviceListDto>();
  const [deviceDtos, setDeviceDtos] = useState<DeviceListDto[]>([]);

  const state = localStorage.getItem(STATE) || '';

  const handleClick = (row: DeviceListDto) => {
    localStorage.setItem(HOLDER_NAME, row.holder);
    window.open(`${DEVICES_INFO_ROUTE}/${row.id}`, '_blank', 'noreferrer');
  };

  const getDevices = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetDevices(state, search);
    if (apiResult.isSuccess) {
      setDeviceDtos(apiResult.data.devices);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getGroups = async () => {
    const apiResult: IApiResult = await apiGetGroups();
    if (apiResult.isSuccess) {
      setGroupDtos(apiResult.data.groups);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const deleteInfo = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: DeviceListDto) => {
    setDeviceDto(dto);
    setOpenDialogConfirmation(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    getDevices();
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton size="small" onClick={() => handleClick(row.row)}>
            <FindInPageIcon
              fontSize="small"
              sx={{ color: MAIN_COLOR_STRING }}
            />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'deviceNum',
      headerName: t('deviceNum'),
      renderHeader: () => <strong>{t('deviceNum')}</strong>,
      minWidth: 150,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => row.row.deviceNum,
    },
    {
      field: 'holder',
      headerName: t('holder'),
      renderHeader: () => <strong>{t('holder')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.holder.replaceAll('_', '"'),
    },
    {
      field: 'grupNum',
      headerName: t('group'),
      renderHeader: () => <strong>{t('group')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) =>
        row.row.grupNum === -1
          ? ''
          : getNameArray(groupDtos)[
              Object.values(getIdArray(groupDtos)).indexOf(row.row.grupNum)
            ],
    },
    {
      field: 'location',
      headerName: t('address'),
      renderHeader: () => <strong>{t('address')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => row.row.location,
    },
    {
      field: 'volumeSuSum',
      headerName: t('shows'),
      renderHeader: () => <strong>{t('shows')}</strong>,
      minWidth: 150,
      editable: false,
      hide: getViewType() === MOBILE_VIEW,
      renderCell: (row) => row.row.volumeSuSum,
    },
    {
      field: 'events',
      headerName: t('events'),
      renderHeader: () => <strong>{t('events')}</strong>,
      minWidth: 150,
      editable: false,
      hide: getViewType() === MOBILE_VIEW,
      renderCell: (row) => row.row.events,
    },
    {
      field: 'dateNextConReal',
      headerName: t('connect'),
      renderHeader: () => <strong>{t('connect')}</strong>,
      minWidth: 200,
      editable: false,
      hide: getViewType() === MOBILE_VIEW,
      renderCell: (row) => row.row.dateNextConReal,
    },
    {
      field: 'id2',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton size="small" onClick={() => confirmDelete(row.row)}>
            <DeleteForeverIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv title={t('groupTask')} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Box
            sx={{
              '& .app-theme-cell': {
                fontFamily: 'sans-serif',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                    fontSize: '100px',
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={columns}
                rows={deviceDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                rowsPerPageOptions={PAGE_SIZE_DEVICES}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        </Stack>
      </Stack>
      <DialogConfirmation
        open={openDialogConfirmation}
        message={t('confirmDeleteFlowmeter')}
        onClose={deleteInfo}
      />
    </div>
  );
};
