import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import {
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
} from '@mui/material';
import {
  deviceType,
  getDefaultAlertProps,
  getSelectedLng,
  getViewType,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  LANGUAGE_RUS,
  PAGE_SIZE_DEVICES,
  PC_VIEW,
  START_PAGE_SIZE_DEVICES,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { FactoryBodyHeaderDiv } from '../body-header/FactoryBodyHeaderDiv';
import { DeviceLicenseDto } from '../../dto/DeviceLicenseDto';
import { apiGetDeviceLicense } from '../../service/device-license/apiGetDeviceLicense';
import { DialogDeviceLicense } from './DialogDeviceLicense';

export const DeviceLicenseDiv = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [deviceLicenseDto, setDevieLicenseDto] = useState<DeviceLicenseDto>({
    id: -1,
    imei: '',
    name: '',
    procId: '',
    deviceMum: '',
    deviceType: -1,
    datetime: -1,
    cnt: -1,
    message: '',
    licKey: '',
    regKey: '',
  });

  const [deviceLicenseDtos, setDeviceLicenseDtos] = useState<
    DeviceLicenseDto[]
  >([]);

  const handleOpenDialog = (dto: DeviceLicenseDto) => {
    setDevieLicenseDto(dto);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getDeviceLicense = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetDeviceLicense();
    if (apiResult.isSuccess) {
      setDeviceLicenseDtos(apiResult.data.license);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    getDeviceLicense();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'imei',
      headerName: t('headerLicenseImei'),
      renderHeader: () => <strong>{t('headerLicenseImei')}</strong>,
      minWidth: 150,
      hide: getViewType() !== PC_VIEW,
      editable: false,
      renderCell: (row) => row.row.imei,
    },
    {
      field: 'procId',
      headerName: t('headerLicenseProcId'),
      renderHeader: () => <strong>{t('headerLicenseProcId')}</strong>,
      minWidth: 250,
      hide: getViewType() !== PC_VIEW,
      editable: false,
      renderCell: (row) => row.row.procId,
    },
    {
      field: 'deviceMum',
      headerName: t('headerLicenseDeviceNum'),
      renderHeader: () => <strong>{t('headerLicenseDeviceNum')}</strong>,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.deviceMum,
    },
    {
      field: 'deviceType',
      headerName: t('headerLicenseDeviceType'),
      renderHeader: () => <strong>{t('headerLicenseDeviceType')}</strong>,
      minWidth: 100,
      editable: false,
      renderCell: (row) => deviceType(row.row.deviceType),
    },
    {
      field: 'regKey',
      headerName: t('headerLicenseRegKey'),
      renderHeader: () => <strong>{t('headerLicenseRegKey')}</strong>,
      flex: 1,
      hide: getViewType() !== PC_VIEW,
      minWidth: 310,
      editable: false,
      renderCell: (row) => row.row.regKey,
    },
    {
      field: 'datetime',
      headerName: t('headerLicenseDatetime'),
      renderHeader: () => <strong>{t('headerLicenseDatetime')}</strong>,
      minWidth: 190,
      editable: false,
      renderCell: (row) => unixToLocal(row.row.datetime),
    },
    {
      field: 'cnt',
      headerName: t('headerLicenseCnt'),
      renderHeader: () => <strong>{t('headerLicenseCnt')}</strong>,
      minWidth: 100,
      hide: getViewType() !== PC_VIEW,
      editable: false,
      renderCell: (row) => row.row.cnt,
    },
    {
      field: 'name',
      headerName: t('headerLicenseName'),
      renderHeader: () => <strong>{t('headerLicenseName')}</strong>,
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            title={t('tooltipLook')}
            onClick={() => handleOpenDialog(row.row)}
          >
            <FindInPageIcon
              fontSize="small"
              sx={{
                color: 'blue',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <FactoryBodyHeaderDiv title={t('deviceLicense')} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Box
            sx={{
              '& .app-theme-cell': {
                fontFamily: 'sans-serif',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                    fontSize: '100px',
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={columns}
                rows={deviceLicenseDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={PAGE_SIZE_DEVICES}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        </Stack>
      </Stack>
      <DialogDeviceLicense
        open={openDialog}
        dto={deviceLicenseDto}
        onClose={handleCloseDialog}
      />
    </div>
  );
};
