import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileDownload from 'js-file-download';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  formatDateArch,
  getDefaultAlertProps,
  getDefaultDateToReport,
  getIdArray,
  getNameArray,
  getReportTypeArray,
  getReportTypeDescription,
} from '../../utils/utils';
import { EXCEL_ARCHIVE, MAIN_COLOR_STRING } from '../../constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { ReportsParamDto } from '../../dto/ReportsParamDto';
import { DocumentTypeEnum } from '../../enum/DocumentTypeEnum';
import { MyDateTextField } from '../UI/MyDateTextField';
import { MyButton } from '../UI/MyButton';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetGroups } from '../../service/group/apiGetGroups';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { GroupDto } from '../../dto/GroupDto';
import { ReportsTypeEnum } from '../../enum/ReportsTypeEnum';
import { apiGetHours } from '../../service/archive/apiGetHours';
import { apiGetDays } from '../../service/archive/apiGetDays';
import { apiGetMonths } from '../../service/archive/apiGetMonths';
import { apiGetEvetns } from '../../service/archive/apiGetEvents';
import { apiGetChanges } from '../../service/archive/apiGetChanges';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { apiGetDevicesToReport } from '../../service/device/apiGetDevicesToReport';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogReport: FunctionComponent<Props> = ({ open, onClose }) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [dateTimeArch, setDateTimeArch] = useState(getDefaultDateToReport(0));
  const reportTypes = getReportTypeArray();
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [progress, setProgress] = useState(-1);
  const [groupDtos, setGroupDtos] = useState<GroupDto[]>([]);
  const [reportParamDto, setReportParamDto] = useState<ReportsParamDto>({
    docType: -1,
    groupId: '',
    reportType: -1,
  });

  const [checkHelper, setCheckHelper] = useState(false);

  const getGroups = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetGroups();
    if (apiResult.isSuccess) {
      setGroupDtos(apiResult.data.groups);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getArch = async (
    idDevice: string,
    type: number,
    print: string,
    datetime?: string,
    name?: string
  ) => {
    if (type === ReportsTypeEnum.HOUR) {
      const apiResult: IApiResult = await apiGetHours(
        idDevice,
        print,
        datetime
      );
      if (apiResult.isSuccess) {
        FileDownload(apiResult.data, `Часовой_${name}` ?? '');
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    if (type === ReportsTypeEnum.DAY) {
      const apiResult: IApiResult = await apiGetDays(idDevice, print, datetime);
      if (apiResult.isSuccess) {
        FileDownload(apiResult.data, `Суточный_${name}` ?? '');
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    if (type === ReportsTypeEnum.MONTH) {
      const apiResult: IApiResult = await apiGetMonths(
        idDevice,
        print,
        datetime
      );
      if (apiResult.isSuccess) {
        FileDownload(apiResult.data, `Месячный_${name}` ?? '');
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    if (type === ReportsTypeEnum.EVENTS) {
      const apiResult: IApiResult = await apiGetEvetns(
        idDevice,
        print,
        datetime
      );
      if (apiResult.isSuccess) {
        FileDownload(apiResult.data, `События_${name}` ?? '');
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    if (type === ReportsTypeEnum.CHANGES) {
      const apiResult: IApiResult = await apiGetChanges(
        idDevice,
        print,
        datetime
      );
      if (apiResult.isSuccess) {
        FileDownload(apiResult.data, `Изменения_${name}` ?? '');
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
  };

  const sleep = (ms: number) =>
    // eslint-disable-next-line no-promise-executor-return
    new Promise((resolve) => setTimeout(resolve, ms));

  const printReport = async (array: DeviceListDto[]) => {
    setProgress(0);
    setBackDrop(true);
    for (let i = 0; i < array.length; i += 1) {
      const reportType = reportParamDto.docType + 1;
      let name = `${array[i].deviceNum}_${formatDateArch(dateTimeArch)}.pdf`;
      if (reportType.toString() === EXCEL_ARCHIVE) {
        name = `${array[i].deviceNum}_${formatDateArch(dateTimeArch)}.xlsx`;
      }
      // eslint-disable-next-line no-await-in-loop
      await getArch(
        array[i].id.toString(),
        reportParamDto.reportType,
        reportType.toString(),
        formatDateArch(dateTimeArch),
        name
      );
      const calcProgerss = ((i + 1) / array.length) * 100;
      setProgress(calcProgerss);
      // eslint-disable-next-line no-await-in-loop
      await sleep(1000);
    }
    setBackDrop(false);
  };

  const getDevices = async (groupId: string) => {
    const apiResult: IApiResult = await apiGetDevicesToReport(groupId);
    if (apiResult.isSuccess) {
      printReport(apiResult.data.devices);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (open) {
      getGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handelCreate = async () => {
    if (
      reportParamDto.docType === -1 ||
      reportParamDto.docType === undefined ||
      reportParamDto.groupId === '' ||
      reportParamDto.groupId === undefined ||
      reportParamDto.reportType === -1 ||
      reportParamDto.reportType === undefined
    ) {
      setCheckHelper(true);
      return;
    }
    getDevices(reportParamDto.groupId);
  };

  const handleClose = () => {
    onClose();
    setCheckHelper(false);
  };

  const [period, setPeriod] = useState('date');

  const handleChangePeriod = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPeriod((event.target as HTMLInputElement).value);
    setDateTimeArch(
      getDefaultDateToReport(0, (event.target as HTMLInputElement).value)
    );
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee' },
        }}
      >
        <BackDropDiv
          open={backDrop}
          progress={progress === -1 ? undefined : progress}
        />
        <DialogTitle>
          <DialogHeaderDiv title={t('groupReport')} onClose={handleClose} />
        </DialogTitle>

        <DialogContent>
          <Stack direction="column" spacing={2}>
            <MyAutoComplete
              value={
                reportParamDto.groupId !== undefined
                  ? getNameArray(groupDtos, false)[
                      Object.values(getIdArray(groupDtos, false)).indexOf(
                        reportParamDto.groupId
                      )
                    ]
                  : ''
              }
              onChange={(e, v) =>
                setReportParamDto({
                  ...reportParamDto,
                  groupId: getIdArray(groupDtos, false)[
                    Object.values(getNameArray(groupDtos, false)).indexOf(v)
                  ],
                })
              }
              arrayList={getNameArray(groupDtos, false)}
              error={checkHelper}
              size="small"
              label={t('group')}
            />
            <MyAutoComplete
              value={
                reportParamDto.reportType !== undefined &&
                reportParamDto.reportType !== -1
                  ? getReportTypeDescription(reportParamDto.reportType)
                  : ''
              }
              onChange={(e, v) =>
                setReportParamDto({
                  ...reportParamDto,
                  reportType: reportTypes.indexOf(v),
                })
              }
              error={checkHelper}
              arrayList={reportTypes}
              size="small"
              label={t('reportType')}
            />
            <MyAutoComplete
              value={
                reportParamDto.docType !== undefined &&
                reportParamDto.docType !== -1
                  ? DocumentTypeEnum[reportParamDto.docType]
                  : ''
              }
              onChange={(e, v) =>
                setReportParamDto({
                  ...reportParamDto,
                  docType: Object.values(DocumentTypeEnum).indexOf(v),
                })
              }
              error={checkHelper}
              arrayList={Object.values(DocumentTypeEnum).filter(
                (value) => typeof value === 'string'
              )}
              size="small"
              label={t('documentFormat')}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack direction="row" spacing={1}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    {t('reportPeriod')}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue="date"
                    onChange={handleChangePeriod}
                  >
                    <FormControlLabel
                      value="date"
                      control={<Radio />}
                      label={t('day')}
                    />
                    <FormControlLabel
                      value="month"
                      control={<Radio />}
                      label={t('month')}
                    />
                    <FormControlLabel
                      value="number"
                      control={<Radio />}
                      label={t('year')}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <MyDateTextField
                type={period}
                value={dateTimeArch}
                onChange={(e) => setDateTimeArch(e.target.value)}
                width="200px"
                label={t('date')}
                size="small"
              />
            </Stack>
            <MyButton
              text={t('form')}
              background={MAIN_COLOR_STRING}
              onClick={handelCreate}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
