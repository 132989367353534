import React from 'react';
import { MainPageLayout } from '../layout/MainPageLayout';
import { BatteryDiv } from '../component/battery/BatteryDiv';
import { FactoryNavigationBar } from '../component/navigation-bar/FactoryNavigationBar';

export const BatteryPage = () => (
  <MainPageLayout
    navigationBar={<FactoryNavigationBar />}
    elementCentr={<BatteryDiv />}
  />
);
