import React, { FunctionComponent, useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  checkUpdateSetupGroupTask,
  formTaskArray,
  getActionDescription,
  getDefaultAlertProps,
  getGroupTaskDto,
  getIdArray,
  getNameArray,
  getSelectedLng,
} from '../../utils/utils';
import { LANGUAGE_RUS, MAIN_COLOR_STRING } from '../../constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { MyButton } from '../UI/MyButton';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { TaskDto } from '../../dto/TaskDto';
import { DialogTaskItem } from './DialogTaskItem';
import { GroupDto } from '../../dto/GroupDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetGroups } from '../../service/group/apiGetGroups';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiAddGroupTask } from '../../service/group-task/apiAddGroupTask';
import { GroupTaskDto } from '../../dto/GroupTaskDto';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogTask: FunctionComponent<Props> = ({ open, onClose }) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [groupDtos, setGroupDtos] = useState<GroupDto[]>([]);
  const [taskChoose, setTaskChoose] = useState<TaskDto[]>(formTaskArray);
  const [taskDtos, setTaskDtos] = useState<TaskDto[]>([]);
  const [groupNum, setGroupNum] = useState('');

  const [checkHelper, setCheckHelper] = useState(false);

  const getGroups = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetGroups();
    if (apiResult.isSuccess) {
      setGroupDtos(apiResult.data.groups);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const handleClose = () => {
    onClose();
    setTaskDtos([]);
    setTaskChoose(formTaskArray);
    setCheckHelper(false);
  };

  const addGroupTask = async (dto: GroupTaskDto) => {
    const apiResult: IApiResult = await apiAddGroupTask(dto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handelCreate = async () => {
    if (groupNum === '' || groupNum === undefined) {
      setCheckHelper(true);
    }
    const dto: GroupTaskDto = getGroupTaskDto(groupNum, taskDtos);
    const errorValid = checkUpdateSetupGroupTask(dto);
    if (errorValid !== '') {
      setAlertProps({
        message: errorValid,
        severity: AlertSeverityEnum.info,
      });
      return;
    }
    await addGroupTask(dto);
  };

  const handleAdd = (dto: TaskDto) => {
    setTaskDtos([...taskDtos, dto]);
    setTaskChoose(taskChoose.filter((a) => a.id !== dto.id));
  };

  const handleDelete = (dto: TaskDto) => {
    setTaskDtos(taskDtos.filter((a) => a.id !== dto.id));
    setTaskChoose([...taskChoose, dto]);
  };

  useEffect(() => {
    if (open) {
      getGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('setup'),
      renderHeader: () => <strong>{t('setup')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="space-between">
          <div>{row.row.name}</div>
          {row.row.id !== 'aut' && row.row.id !== 'adt' && row.row.id !== 'am' && (
            <div>
              <strong>{row.row.value}</strong>
            </div>
          )}
          {row.row.id === 'am' && (
            <div>
              <strong>{getActionDescription(row.row.value)}</strong>
            </div>
          )}
          {(row.row.id === 'adt' || row.row.id === 'aut') && (
            <div>
              <strong>{getActionDescription(row.row.value)}</strong>
            </div>
          )}
        </Stack>
      ),
    },
    {
      field: 'id2',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton size="small">
            <DeleteForeverIcon
              onClick={() => handleDelete(row.row)}
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', height: '100%', width: '100%' },
        }}
      >
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <BackDropDiv open={backDrop} />
        <DialogTitle>
          <DialogHeaderDiv title={t('groupTask')} onClose={handleClose} />
        </DialogTitle>

        <DialogContent>
          <Stack direction="column" height="100%" spacing={2}>
            <MyAutoComplete
              value={
                groupNum !== undefined && groupNum !== ''
                  ? getNameArray(groupDtos, false)[
                      Object.values(getIdArray(groupDtos, false)).indexOf(
                        groupNum
                      )
                    ]
                  : ''
              }
              onChange={(e, v) =>
                setGroupNum(
                  getIdArray(groupDtos, false)[
                    Object.values(getNameArray(groupDtos, false)).indexOf(v)
                  ]
                )
              }
              maxHeightDropList="400px"
              arrayList={getNameArray(groupDtos, false)}
              error={checkHelper}
              size="small"
              label={t('group')}
            />
            <Stack direction="column" width="100%" spacing={1}>
              <Typography
                style={{
                  paddingTop: '10px',
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                {taskDtos.length > 0 ? t('taskList') : t('taskListEmpty')}
              </Typography>
            </Stack>
            {taskDtos.length > 0 && (
              <Stack spacing={1}>
                <Box
                  sx={{
                    '& .app-theme-cell': {
                      fontFamily: 'sans-serif',
                    },
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <DataGrid
                      sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                          {
                            py: 1,
                            fontSize: '100px',
                          },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                          {
                            py: '5px',
                          },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                          {
                            py: '10px',
                          },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                          {
                            outline: 'none !important',
                          },
                      }}
                      getRowHeight={() => 'auto'}
                      columns={columns}
                      rows={taskDtos}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...other}
                      pageSize={15}
                      headerHeight={0}
                      hideFooter
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </ThemeProvider>
                </Box>
              </Stack>
            )}
            <DialogTaskItem
              valueSel=""
              paramSel=""
              isAdd
              chooseParams={taskChoose}
              onClickAdd={handleAdd}
            />
            <div style={{ flex: 1 }} />
            <Stack paddingBottom="20px">
              <MyButton
                disabled={!(taskDtos.length > 0)}
                text={t('taskDone')}
                background={MAIN_COLOR_STRING}
                onClick={handelCreate}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
