import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  APP_TOKEN_ROUTE,
  BATTERY_ROUTE,
  DEVICES_ARCHIVE_ROUTE,
  DEVICES_CHART_ROUTE,
  DEVICES_INFO_ROUTE,
  DEVICES_ROUTE,
  DEVICES_SETUP_ROUTE,
  DEVICE_PASSWORDS_ROUTE,
  FACTORY_ROUTE,
  FACTORY_TOKEN_ROUTE,
  GROUP_TASK_ROUTE,
  LICENSE_RESULT_ROUTE,
  LOCAL_SERVER_ACTIVATE_ROUTE,
  LOG_ROUTE,
  MAIN_ROUTE,
  SERVICE_ROUTE,
  SERVICE_TOKEN_ROUTE,
  SIGN_IN_ROUTE,
} from '../../constant/routes';
import { DevicesPage } from '../../page/DevicesPage';
import { MainPage } from '../../page/MainPage';
import { SignInPage } from '../../page/SignInPage';
import { DeviceInfoPage } from '../../page/DeviceInfoPage';
import { DeviceArchivePage } from '../../page/DeviceArchivePage';
import { DeviceChartPage } from '../../page/DeviceChartPage';
import { DeviceSetupPage } from '../../page/DeviceSetupPage';
import { LogPage } from '../../page/LogPage';
import { GroupTaskPage } from '../../page/GroupTaskPage';
import { ServicePage } from '../../page/ServicePage';
import { AppTokenPage } from '../../page/AppTokenPage';
import { BatteryPage } from '../../page/BatteryPage';
import { DevicePasswordsPage } from '../../page/DevicePasswordsPage';
import { FactoryPage } from '../../page/FactoryPage';
import { ServiceTokenPage } from '../../page/ServiceTokenPage';
import { FactoryTokenPage } from '../../page/FactoryTokenPage';
import { LocalServerPage } from '../../page/LocalServerPage';
import { DeviceLicensePage } from '../../page/DeviceLicensePage';

export const App = () => (
  <div>
    <Routes>
      <Route path={SIGN_IN_ROUTE} element={<SignInPage />} />
      <Route path={MAIN_ROUTE} element={<MainPage />} />
      <Route path={SERVICE_ROUTE} element={<ServicePage />} />
      <Route path={DEVICES_ROUTE} element={<DevicesPage />} />
      <Route path={LOG_ROUTE} element={<LogPage />} />
      <Route path={APP_TOKEN_ROUTE} element={<AppTokenPage />} />
      <Route path={SERVICE_TOKEN_ROUTE} element={<ServiceTokenPage />} />
      <Route path={`${BATTERY_ROUTE}`} element={<BatteryPage />} />
      <Route path={`${BATTERY_ROUTE}/:id`} element={<BatteryPage />} />
      <Route path={`${DEVICES_ROUTE}/:search`} element={<DevicesPage />} />
      <Route path={`${DEVICES_INFO_ROUTE}/:id`} element={<DeviceInfoPage />} />
      <Route path={GROUP_TASK_ROUTE} element={<GroupTaskPage />} />
      <Route
        path={`${DEVICE_PASSWORDS_ROUTE}`}
        element={<DevicePasswordsPage />}
      />
      <Route
        path={`${DEVICES_ARCHIVE_ROUTE}/:id`}
        element={<DeviceArchivePage />}
      />
      <Route
        path={`${DEVICES_CHART_ROUTE}/:id`}
        element={<DeviceChartPage />}
      />
      <Route
        path={`${DEVICES_SETUP_ROUTE}/:id`}
        element={<DeviceSetupPage />}
      />
      <Route path={FACTORY_ROUTE} element={<FactoryPage />} />
      <Route path={FACTORY_TOKEN_ROUTE} element={<FactoryTokenPage />} />
      <Route path={LOCAL_SERVER_ACTIVATE_ROUTE} element={<LocalServerPage />} />
      <Route path={LICENSE_RESULT_ROUTE} element={<DeviceLicensePage />} />
    </Routes>
  </div>
);

export default App;
