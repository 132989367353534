import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiDeleteUpdate = async (dto: DeviceListDto) => {
  const params: IApiParams = {
    url: '/service/deleteUpdate.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'DELETE', dto);
};
