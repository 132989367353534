import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiGetLinks = async () => {
  const params: IApiParams = {
    url: '/link-flowmeter/getLinks.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'GET');
};
