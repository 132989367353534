import React, { FunctionComponent, useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  getDefaultAlertProps,
  getRootArray,
  getRootDescription,
  getSelectedLng,
  getViewType,
  otherToDataGrid,
} from '../../utils/utils';
import {
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  PC_VIEW,
} from '../../constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { MyTextFiled } from '../UI/MyTextField';
import { AccountDto } from '../../dto/AccountDto';
import { MyPasswordTextField } from '../UI/MyPasswordTextField';
import { DialogConfirmation } from '../confirmation/DialogConfirmation';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetUsers } from '../../service/account/apiGetUsers';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiSignUp } from '../../service/account/apiSignUp';
import { apiDeleteUser } from '../../service/account/apiDeleteUser';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogAccount: FunctionComponent<Props> = ({ open, onClose }) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [accountDeleteDto, setAccountDeleteDto] = useState<AccountDto>({
    id: -1,
    login: '',
    password: '',
    name: '',
    root: -1,
  });
  const [accountDto, setAccountDto] = useState<AccountDto>({
    id: -1,
    login: '',
    password: '',
    name: '',
    root: -1,
  });
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [accountDtos, setAccountDtos] = useState<AccountDto[]>([]);
  const rootTypes = getRootArray();
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);

  const [checkHelper, setCheckHelper] = useState(false);

  const getUsers = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetUsers();
    if (apiResult.isSuccess) {
      setAccountDtos(apiResult.data.users);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const addUser = async (dto: AccountDto) => {
    const apiResult: IApiResult = await apiSignUp(dto);
    if (apiResult.isSuccess) {
      setAccountDto({
        id: -1,
        login: '',
        password: '',
        name: '',
        root: -1,
      });
      getUsers();
    } else if (apiResult.status === 409) {
      setAlertProps({
        message: t('errorLoginConflict'),
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (open) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handelCreate = async () => {
    if (
      accountDto.login === '' ||
      accountDto.password === '' ||
      accountDto.name === '' ||
      accountDto.root === -1
    ) {
      setCheckHelper(true);
      return;
    }
    await addUser(accountDto);
  };

  const handleClose = () => {
    onClose();
    setAccountDto({
      id: -1,
      login: '',
      password: '',
      name: '',
      root: -1,
    });
    setCheckHelper(false);
  };

  const deleteUser = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const apiResult: IApiResult = await apiDeleteUser(accountDeleteDto);
    if (apiResult.isSuccess) {
      setAccountDeleteDto({
        id: -1,
        login: '',
        password: '',
        name: '',
        root: -1,
      });
      getUsers();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: AccountDto) => {
    setAccountDeleteDto(dto);
    setOpenDialogConfirmation(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('loginName'),
      renderHeader: () => <strong>{t('loginName')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'root',
      headerName: t('root'),
      renderHeader: () => <strong>{t('root')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => getRootDescription(row.row.root),
    },
    {
      field: 'login',
      headerName: t('login'),
      renderHeader: () => <strong>{t('login')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.login,
    },
    {
      field: 'flowmeterCnt',
      headerName: t('flowmeterCnt'),
      renderHeader: () => <strong>{t('flowmeterCnt')}</strong>,
      minWidth: 200,
      hide: getViewType() !== PC_VIEW,
      flex: 1,
      editable: false,
      renderCell: (row) => row.row.flowmeterCnt,
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton size="small" onClick={() => confirmDelete(row.row)}>
            <DeleteForeverIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', height: '100%', width: '100%' },
        }}
      >
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <BackDropDiv open={backDrop} />
        <DialogTitle>
          <DialogHeaderDiv title={t('accountEdit')} onClose={handleClose} />
        </DialogTitle>

        <DialogContent>
          <Stack direction="row" spacing={1}>
            <MyTextFiled
              value={accountDto?.name}
              onChange={(e) =>
                setAccountDto({ ...accountDto, name: e.target.value })
              }
              autoComplete="off"
              size="small"
              error={checkHelper}
              label={t('loginName')}
            />
            <MyAutoComplete
              value={
                accountDto.root !== undefined && accountDto.root !== -1
                  ? getRootDescription(accountDto.root)
                  : ''
              }
              onChange={(e, v) =>
                setAccountDto({
                  ...accountDto,
                  root: rootTypes.indexOf(v) + 1,
                })
              }
              error={checkHelper}
              arrayList={rootTypes}
              size="small"
              label={t('root')}
            />
            <MyTextFiled
              value={accountDto?.login}
              autoComplete="off"
              onChange={(e) =>
                setAccountDto({ ...accountDto, login: e.target.value })
              }
              size="small"
              error={checkHelper}
              label={t('login')}
            />
            <MyPasswordTextField
              value={accountDto?.password}
              inputPassword="new-password"
              onChange={(e) =>
                setAccountDto({ ...accountDto, password: e.target.value })
              }
              size="small"
              error={checkHelper}
              label={t('password')}
            />
            <Stack alignItems="center">
              <IconButton
                sx={{ color: MAIN_COLOR_STRING }}
                onClick={handelCreate}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Stack paddingTop="20px" spacing={1}>
            <Box
              sx={{
                '& .app-theme-cell': {
                  fontFamily: 'sans-serif',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                      fontSize: '100px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '10px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={accountDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...otherToDataGrid}
                  pageSize={15}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
      <DialogConfirmation
        open={openDialogConfirmation}
        message={t('confirmDeleteUser')}
        onClose={deleteUser}
      />
    </div>
  );
};
