import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
} from '@mui/material';
import {
  getDefaultAlertProps,
  getSelectedLng,
  getViewType,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  PAGE_SIZE_DEVICES,
  PC_VIEW,
  START_PAGE_SIZE_DEVICES,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { GroupIdDto } from '../../dto/GroupIdDto';
import { BatQrCodeDto } from '../../dto/BatQrCodeDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetGroupId } from '../../service/bat-qr/apiGetGroupId';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiGetQrCode } from '../../service/bat-qr/apiGetQrCode';
import { apiAddQrCode } from '../../service/bat-qr/apiAddQrCode';
import { MyTextFiled } from '../UI/MyTextField';
import { apiAddGroupId } from '../../service/bat-qr/apiAddGroupId';
import { FactoryBodyHeaderDiv } from '../body-header/FactoryBodyHeaderDiv';
import { BATTERY_ROUTE } from '../../constant/routes';
import { apiPrintQrCode } from '../../service/bat-qr/apiPrintQrCode';

export const BatteryDiv = () => {
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const { id } = useParams();
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [groupIdDto, setGroupIdDto] = useState<GroupIdDto>({
    id: -1,
    groupId: id ?? '',
    name: '',
    datetime: '',
    note: '',
  });

  const [groupIdDtos, setGroupIdDtos] = useState<GroupIdDto[]>([]);
  const [qrCodeDtos, setQrCodeDtos] = useState<BatQrCodeDto[]>([]);

  const getGroupIdDtos = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetGroupId();
    if (apiResult.isSuccess) {
      setGroupIdDtos(apiResult.data.group);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getQrCodeDros = async (groupId: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetQrCode(groupId);
    if (apiResult.isSuccess) {
      setQrCodeDtos(apiResult.data.qrCode);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const [checkHelper, setCheckHelper] = useState(false);

  const addGroupId = async (dto: GroupIdDto) => {
    const apiResult: IApiResult = await apiAddGroupId(dto);
    if (apiResult.isSuccess) {
      getGroupIdDtos();
      setGroupIdDto({
        id: -1,
        groupId: id ?? '',
        name: '',
        datetime: '',
        note: '',
      });
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const addQrCode = async (dto: GroupIdDto) => {
    const apiResult: IApiResult = await apiAddQrCode(dto);
    if (apiResult.isSuccess) {
      getQrCodeDros(id ?? '');
      setGroupIdDto({
        id: -1,
        groupId: id ?? '',
        name: '',
        datetime: '',
        note: '',
        cnt: undefined,
      });
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const printQrCode = async (groupId: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiPrintQrCode(groupId);
    if (apiResult.isSuccess) {
      FileDownload(apiResult.data, `QR-коды.pdf` ?? '');
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const handelCreate = async () => {
    if (groupIdDto?.name === '' || groupIdDto?.note === '') {
      setCheckHelper(true);
      return;
    }
    setCheckHelper(false);
    await addGroupId(groupIdDto);
  };

  const handelCreateQrCode = async () => {
    if (groupIdDto?.cnt === undefined) {
      setCheckHelper(true);
      return;
    }
    setCheckHelper(false);
    await addQrCode(groupIdDto);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    if (id === undefined) {
      getGroupIdDtos();
    } else {
      getQrCodeDros(id ?? '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsGroupId: GridColDef[] = [
    {
      field: 'groupId',
      headerName: t('headerBatteryGroupId'),
      renderHeader: () => <strong>{t('headerBatteryGroupId')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => (
        <Stack width="100%">
          <a
            href={`${BATTERY_ROUTE}/${row.row.groupId}`}
            target="_blank"
            rel="noreferrer"
          >
            {row.row.groupId}
          </a>
        </Stack>
      ),
    },
    {
      field: 'name',
      headerName: t('headerBatteryName'),
      renderHeader: () => <strong>{t('headerBatteryName')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'note',
      headerName: t('headerBatteryNote'),
      renderHeader: () => <strong>{t('headerBatteryNote')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => row.row.note,
    },
    {
      field: 'datetime',
      headerName: t('headerBatteryDatetime'),
      renderHeader: () => <strong>{t('headerBatteryDatetime')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => unixToLocal(row.row.datetime),
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton onClick={() => printQrCode(row.row.groupId)}>
            <PictureAsPdfIcon
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsQrCodeBattery: GridColDef[] = [
    {
      field: 'qrCode',
      headerName: t('headerBatteryQrCode'),
      renderHeader: () => <strong>{t('headerBatteryQrCode')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.qrCode,
    },
    {
      field: 'datetimeCreate',
      headerName: t('headerBatteryDatetime'),
      renderHeader: () => <strong>{t('headerBatteryDatetime')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => unixToLocal(row.row.datetimeCreate),
    },
    {
      field: 'datetimePair',
      headerName: t('headerBatteryDatetimePair'),
      renderHeader: () => <strong>{t('headerBatteryDatetimePair')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => unixToLocal(row.row.datetimePair),
    },
    {
      field: 'deviceNum',
      headerName: t('headerBatteryDeviceNum'),
      renderHeader: () => <strong>{t('headerBatteryDeviceNum')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.deviceNum,
    },
    {
      field: 'startBat',
      headerName: t('headerBatteryStartBat'),
      renderHeader: () => <strong>{t('headerBatteryStartBat')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.startBat,
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <FactoryBodyHeaderDiv title={t('batteryActivate')} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Stack direction="row" spacing={1}>
            {id === undefined && (
              <MyTextFiled
                value={groupIdDto.name}
                onChange={(e) =>
                  setGroupIdDto({
                    ...groupIdDto,
                    name: e.target.value,
                  })
                }
                autoComplete="off"
                size="small"
                error={checkHelper}
                label={t('textBatteryName')}
              />
            )}
            {id === undefined && (
              <MyTextFiled
                value={groupIdDto.note}
                autoComplete="off"
                onChange={(e) =>
                  setGroupIdDto({ ...groupIdDto, note: e.target.value })
                }
                size="small"
                error={checkHelper}
                label={t('textBatteryNote')}
              />
            )}
            {id !== undefined && (
              <MyTextFiled
                value={groupIdDto.cnt}
                autoComplete="off"
                onChange={(e) =>
                  setGroupIdDto({ ...groupIdDto, cnt: e.target.value })
                }
                type="number"
                size="small"
                error={checkHelper}
                label={t('textBatteryCnt')}
              />
            )}
            <Stack alignItems="center">
              <IconButton
                sx={{ color: MAIN_COLOR_STRING }}
                onClick={id === undefined ? handelCreate : handelCreateQrCode}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Box
            sx={{
              '& .app-theme-cell': {
                fontFamily: 'sans-serif',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                    fontSize: '100px',
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={
                  id === undefined ? columnsGroupId : columnsQrCodeBattery
                }
                rows={id === undefined ? groupIdDtos : qrCodeDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={PAGE_SIZE_DEVICES}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        </Stack>
      </Stack>
    </div>
  );
};
