/* eslint-disable no-unused-vars */
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Autocomplete, TextField } from '@mui/material';

interface Props {
  label: string;
  arrayList: any;
  disabled?: boolean;
  width?: string;
  size?: any;
  error?: boolean;
  helperText?: string;
  maxHeightDropList?: string;
  value?: string;
  valueTask?: any;
  onChange?: (e: any, v: any) => void;
}

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'grey',
  },
});

export const MyAutoCompleteTask: FunctionComponent<Props> = ({
  label,
  arrayList,
  disabled,
  width,
  size,
  error,
  helperText,
  maxHeightDropList,
  value,
  valueTask,
  onChange,
}) => {
  const [isChange, setIsChange] = useState(false);

  const getValue = () => {
    if (isChange) {
      return valueTask;
    }
    return valueTask !== '' ? valueTask : value;
  };

  const getColor = () => {
    if (`${valueTask}` === `${value}`) {
      return undefined;
    }
    if (`${valueTask}` !== '' && `${valueTask}` !== `${value}`) {
      return '#a5d6a7';
    }
    return undefined;
  };

  const handleChange = (e: any, v: any) => {
    setIsChange(true);
    if (onChange !== undefined) {
      onChange(e, v);
    }
  };

  const { t } = useTranslation();
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      value={getValue()}
      disabled={disabled}
      onChange={handleChange}
      style={{ width }}
      options={arrayList}
      ListboxProps={{
        style: { maxHeight: maxHeightDropList },
      }}
      renderInput={(params) => (
        <CssTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={label}
          disabled={disabled}
          error={error && value === ''}
          variant="filled"
          style={{
            background: getColor(),
            fontFamily: 'sans-serif',
          }}
          helperText={error && value === '' ? t('enterValue') : helperText}
          size={size}
        />
      )}
    />
  );
};

MyAutoCompleteTask.defaultProps = {
  value: '',
  valueTask: '',
  width: '100%',
  size: 'large',
  error: false,
  helperText: '',
  maxHeightDropList: '200px',
  disabled: false,
  onChange: undefined,
};
