import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { ServiceListDto } from '../../dto/ServiceListDto';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiDeleteDevice = async (dto?: ServiceListDto) => {
  const params: IApiParams = {
    url: '/service/deleteDevice.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'DELETE', dto);
};
