export const SIGN_IN_ROUTE = '/';
export const MAIN_ROUTE = '/main';
export const DEVICES_ROUTE = '/device';
export const DEVICES_INFO_ROUTE = '/info';
export const DEVICES_ARCHIVE_ROUTE = '/archive';
export const DEVICES_CHART_ROUTE = '/chart';
export const DEVICES_SETUP_ROUTE = '/setup';
export const SERVICE_ROUTE = '/service';
export const LOG_ROUTE = '/logs';
export const GROUP_TASK_ROUTE = '/group-task';
export const APP_TOKEN_ROUTE = '/app-token';
export const BATTERY_ROUTE = '/battery';
export const DEVICE_PASSWORDS_ROUTE = '/device-passwords';

export const FACTORY_ROUTE = '/factory';
export const SERVICE_TOKEN_ROUTE = '/service-token';
export const FACTORY_TOKEN_ROUTE = '/factory-token';
export const LOCAL_SERVER_ACTIVATE_ROUTE = '/local-server';
export const LICENSE_RESULT_ROUTE = '/check';
