import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { TelegramDto } from '../../dto/TelegramDto';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiAddTelegram = async (dto: TelegramDto) => {
  const params: IApiParams = {
    url: '/telegram/addTelegram.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', dto);
};
