import React, { FunctionComponent } from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
  param: any;
  color?: string;
  value?: any;
  tooltip?: any;
  isColumn?: boolean;
}

export const DialogInfoItem: FunctionComponent<Props> = ({
  param,
  color,
  value,
  tooltip,
  isColumn,
}) => (
  <div title={tooltip}>
    <Stack
      direction={isColumn ? 'column' : 'row'}
      justifyContent="space-between"
      spacing={1}
    >
      <Typography
        style={{
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          color: '#000000',
          cursor: 'pointer',
        }}
        component="text"
      >
        {param}
      </Typography>
      <Typography
        style={{
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          color,
          cursor: 'pointer',
        }}
        component="text"
      >
        {value}
      </Typography>
    </Stack>
  </div>
);

DialogInfoItem.defaultProps = {
  value: '',
  color: '#000000',
  tooltip: undefined,
  isColumn: false,
};
