import React from 'react';
import { MainPageLayout } from '../layout/MainPageLayout';
import { FactoryNavigationBar } from '../component/navigation-bar/FactoryNavigationBar';
import { LocalServerDiv } from '../component/local-server/LocalServerDiv';

export const LocalServerPage = () => (
  <MainPageLayout
    navigationBar={<FactoryNavigationBar />}
    elementCentr={<LocalServerDiv />}
  />
);
