import React, { FunctionComponent, useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  getDefaultAlertProps,
  getRootDescription,
  getSelectedLng,
  getViewType,
  otherToDataGrid,
} from '../../utils/utils';
import {
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  PC_VIEW,
} from '../../constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { AccountDto } from '../../dto/AccountDto';
import { apiGetLinks } from '../../service/link/apiGetLinks';
import { apiUpdateLink } from '../../service/link/apiUpdateLink';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogLinkFlowmeter: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [accountDtos, setAccountDtos] = useState<AccountDto[]>([]);
  const [, setAccountDto] = useState<AccountDto>({
    id: -1,
    login: '',
    password: '',
    name: '',
    root: -1,
    flowmeterGroupId: '',
  });

  const getLinks = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetLinks();
    if (apiResult.isSuccess) {
      setAccountDtos(apiResult.data.links);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const updateLink = async (dto: AccountDto) => {
    const apiResult: IApiResult = await apiUpdateLink(dto);
    if (apiResult.isSuccess) {
      setAccountDto({
        id: -1,
        login: '',
        password: '',
        name: '',
        root: -1,
      });
      getLinks();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (open) {
      getLinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    onClose();
    setAccountDto({
      id: -1,
      login: '',
      password: '',
      name: '',
      root: -1,
      flowmeterGroupId: '',
    });
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('loginName'),
      renderHeader: () => <strong>{t('loginName')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'root',
      headerName: t('root'),
      renderHeader: () => <strong>{t('root')}</strong>,
      flex: 1,
      hide: getViewType() !== PC_VIEW,
      minWidth: 200,
      editable: false,
      renderCell: (row) => getRootDescription(row.row.root),
    },
    {
      field: 'login',
      headerName: t('login'),
      renderHeader: () => <strong>{t('login')}</strong>,
      flex: 2,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.login,
    },
    {
      field: 'flowmeterGroupId',
      headerName: t('flowmeterGroupId'),
      renderHeader: () => <strong>{t('flowmeterGroupId')}</strong>,
      flex: 2,
      minWidth: 200,
      editable: true,
      renderCell: (row) => row.row.flowmeterGroupId,
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton size="small" onClick={() => updateLink(row.row)}>
            <SaveIcon
              fontSize="small"
              sx={{
                color: MAIN_COLOR_STRING,
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', height: '100%', width: '100%' },
        }}
      >
        <BackDropDiv open={backDrop} />
        <DialogTitle>
          <DialogHeaderDiv title={t('userLink')} onClose={handleClose} />
        </DialogTitle>

        <DialogContent>
          <Stack direction="column" height="100%" spacing={2}>
            <Stack paddingTop="20px" spacing={1}>
              <Box
                sx={{
                  '& .app-theme-cell': {
                    fontFamily: 'sans-serif',
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    sx={{
                      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: 1,
                        fontSize: '100px',
                      },
                      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '5px',
                      },
                      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        {
                          py: '10px',
                        },
                      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                          outline: 'none !important',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    rows={accountDtos}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...otherToDataGrid}
                    pageSize={15}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                </ThemeProvider>
              </Box>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
