import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

interface Props {
  label: any;
  autoComplete?: string;
  disabled?: boolean;
  multiLine?: boolean;
  width?: string;
  size?: any;
  value?: any;
  valueTask?: any;
  type?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (e: any) => void;
}

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'grey',
  },
});

export const MyTextFiledTask: FunctionComponent<Props> = ({
  label,
  autoComplete,
  multiLine,
  disabled,
  width,
  size,
  value,
  valueTask,
  type,
  error,
  helperText,
  onChange,
}) => {
  const [isChange, setIsChange] = useState(false);

  const getValue = () => {
    if (isChange) {
      return valueTask;
    }
    return valueTask !== '' ? valueTask : value;
  };

  const getColor = () => {
    if (`${valueTask}` === `${value}`) {
      return undefined;
    }
    if (`${valueTask}` !== '' && `${valueTask}` !== `${value}`) {
      return '#a5d6a7';
    }
    return undefined;
  };

  const handleChange = (e: any) => {
    setIsChange(true);
    if (onChange !== undefined) {
      onChange(e);
    }
  };

  const { t } = useTranslation();
  return (
    <CssTextField
      label={label}
      value={getValue()}
      autoComplete={autoComplete}
      variant="filled"
      type={type}
      onChange={handleChange}
      error={(error && value === '') || helperText !== ''}
      multiline={multiLine}
      disabled={disabled}
      inputProps={{
        style: { background: getColor() },
      }}
      style={{
        width,
        fontFamily: 'sans-serif',
      }}
      helperText={error && value === '' ? t('enterValue') : helperText}
      size={size}
    />
  );
};

MyTextFiledTask.defaultProps = {
  value: '',
  valueTask: '',
  autoComplete: 'off',
  disabled: false,
  multiLine: false,
  width: '100%',
  size: 'large',
  type: 'text',
  error: false,
  helperText: '',
  onChange: undefined,
};
