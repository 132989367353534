export enum DeviceStageEnum {
  ALL = 0,
  TASK = 1,
  WARNING = 2,
  CONNECT = 3,
  UPDATE = 4,
  ARCHIVE = 5,
  SERTIFICATE = 6,
  BATTERY = 7,
  SERVER_LOGS = 8,
  SEARCH = 9,
  VALVE = 10,
}
