import { FORM_DATA_CONTENT_TYPE, TOKEN_NAME } from '../constant/constants';
import { IApiParams } from '../interface/IApiParams';
import { IFileUpload } from '../interface/IFileUpload';
import { getApi } from './getApi';

export const apiUploadFile = async (fileUpload: IFileUpload) => {
  const params: IApiParams = {
    url: `/addFile.php`,
    headers: {
      'Content-Type': FORM_DATA_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', fileUpload.data);
};
