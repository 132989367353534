import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiGetQrCode = async (groupId: string) => {
  const params: IApiParams = {
    url: `/bat-qr-code/getBarQrCode.php?groupId=${groupId}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'GET');
};
