import { IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  getActionDescription,
  getIdTaskArray,
  getMagnetFlowActionArray,
  getNameTaskArray,
  getTempActionArray,
  getTypeTaskArray,
} from '../../utils/utils';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { MyTextFiled } from '../UI/MyTextField';
import { TaskDto } from '../../dto/TaskDto';
import { MAIN_COLOR_STRING } from '../../constant/constants';

interface Props {
  chooseParams: TaskDto[];
  valueSel: any;
  paramSel: string;
  isAdd: boolean;
  onClickAdd: (e: any) => void;
}

export const DialogTaskItem: FunctionComponent<Props> = ({
  chooseParams,
  valueSel,
  paramSel,
  isAdd,
  onClickAdd,
}) => {
  const [value, setValue] = useState(valueSel);
  const { t } = useTranslation();
  const [checkHelper, setCheckHelper] = useState(false);
  const [param, setParam] = useState(paramSel);
  const magnetFlowActions = getMagnetFlowActionArray();
  const tempActions = getTempActionArray();

  const addParams = () => {
    if (
      param === '' ||
      value === '' ||
      param === undefined ||
      value === undefined
    ) {
      setCheckHelper(true);
      return;
    }
    const taskDto: TaskDto = {
      id: param,
      value,
      type:
        param !== undefined
          ? getTypeTaskArray(chooseParams)[
              Object.values(getIdTaskArray(chooseParams)).indexOf(param)
            ]
          : '',
      name:
        param !== undefined
          ? getNameTaskArray(chooseParams)[
              Object.values(getIdTaskArray(chooseParams)).indexOf(param)
            ]
          : '',
    };
    setCheckHelper(false);
    setValue('');
    setParam('');
    onClickAdd(taskDto);
  };
  return (
    <div>
      <Stack direction="row" spacing={1}>
        <MyAutoComplete
          maxHeightDropList="400px"
          value={
            param !== undefined
              ? getNameTaskArray(chooseParams)[
                  Object.values(getIdTaskArray(chooseParams)).indexOf(param)
                ]
              : ''
          }
          width="160%"
          disabled={!isAdd}
          onChange={(e, v) =>
            setParam(
              getIdTaskArray(chooseParams)[
                Object.values(getNameTaskArray(chooseParams)).indexOf(v)
              ]
            )
          }
          error={checkHelper}
          arrayList={getNameTaskArray(chooseParams)}
          size="small"
          label={t('setup')}
        />
        {param !== 'aut' && param !== 'adt' && param !== 'am' && (
          <MyTextFiled
            value={value}
            disabled={!isAdd}
            error={checkHelper}
            onChange={(e) => setValue(e.target.value)}
            size="small"
            type={
              getTypeTaskArray(chooseParams)[
                Object.values(getIdTaskArray(chooseParams)).indexOf(param)
              ]
            }
            label={t('value')}
          />
        )}
        {param === 'am' && (
          <MyAutoComplete
            maxHeightDropList="200"
            value={
              value !== undefined && value !== -1
                ? getActionDescription(value)
                : ''
            }
            disabled={!isAdd}
            onChange={(e, v) => setValue(magnetFlowActions.indexOf(v))}
            error={checkHelper}
            arrayList={magnetFlowActions}
            size="small"
            label={t('value')}
          />
        )}
        {(param === 'adt' || param === 'aut') && (
          <MyAutoComplete
            maxHeightDropList="200"
            value={
              value !== undefined && value !== -1
                ? getActionDescription(value)
                : ''
            }
            disabled={!isAdd}
            onChange={(e, v) => setValue(tempActions.indexOf(v))}
            error={checkHelper}
            arrayList={tempActions}
            size="small"
            label={t('value')}
          />
        )}
        <Stack alignItems="center">
          <IconButton sx={{ color: MAIN_COLOR_STRING }} onClick={addParams}>
            <AddIcon />
          </IconButton>
        </Stack>
      </Stack>
    </div>
  );
};
