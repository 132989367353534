import React, { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

interface Props {
  label: any;
  disabled?: boolean;
  value?: any;
  valueTask?: any;
  onChange?: (e: any) => void;
}

export const MyCheckBoxTask: FunctionComponent<Props> = ({
  label,
  disabled,
  value,
  valueTask,
  onChange,
}) => {
  const getValue = () => (valueTask !== '' ? valueTask : value);

  const getColor = () => {
    if (`${valueTask}` === `${value}`) {
      return undefined;
    }
    if (`${valueTask}` !== '' && `${valueTask}` !== `${value}`) {
      return '#a5d6a7';
    }
    return undefined;
  };

  return (
    <FormControlLabel
      sx={{ background: getColor() }}
      control={
        <Checkbox
          checked={getValue()}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

MyCheckBoxTask.defaultProps = {
  value: '',
  valueTask: '',
  disabled: false,
  onChange: undefined,
};
